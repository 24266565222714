import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { StaticAlertsService } from 'modules/alerts/services/static-alerts.service';
import { AlertMessagesComponent } from 'modules/alerts/view/alert-messages.component';

declare const angular: angular.IAngularStatic;

export default angular
  .module('kmi.lms.core')
  .factory('staticAlertsService', downgradeInjectable(StaticAlertsService))
  .directive('alertMessages', downgradeComponent({ component: AlertMessagesComponent }));
