<div *ngIf="filter.visible">
  <div ngbAccordion *ngIf="!fullScreen" class="truncate-labels">
    <div ngbAccordionItem [collapsed]="!isOpen">
      <div ngbAccordionHeader>
        <button type="button" ngbAccordionButton>
          <span class="pull-right fa fa-fw fa-caret-down"></span>
          <span>{{ filter.label }}</span>
        </button>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <div class="flex" *ngIf="filter.selectedItems.length > 0">
              <div class="flex-row">
                <div class="flex-col flex-col-grow">
                  <a href="#" title="" (click)="openFilter()">{{ filter.selectedItems.length }} items selected</a>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-row">
                <div class="flex-col flex-col-grow">
                  <button
                    type="button"
                    class="btn btn-success btn-block"
                    *ngIf="filter.selectedItems.length <= 0"
                    (click)="openFilter()">
                    Select {{ filter.label }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger btn-block"
                    *ngIf="filter.selectedItems.length > 0"
                    (click)="filter.clearSelection()">
                    Remove {{ filter.label }}
                  </button>
                  <boolean-sub-filter-dep
                    *ngIf="filter.subfilter !== undefined && filter.selectedItems.length > 0"
                    [filter]="filter.subfilter"></boolean-sub-filter-dep>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="fullScreen" class="">
    <searchable-multi-choice-content-dep [filter]="filter"></searchable-multi-choice-content-dep>
  </div>
</div>
