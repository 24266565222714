import { Injectable } from '@angular/core';
import { IEnvironment } from './models/environment.models';

@Injectable()
export class EnvironmentService {
  private environment: IEnvironment;

  constructor() {
    if ((<any>window).elmsEnvironment) {
      this.environment = (<any>window).elmsEnvironment as IEnvironment;
    }
  }

  get serverTimeZone(): string {
    return this.environment?.serverTimeZone || 'America/New_York';
  }

  get settings() {
    return this.environment?.settings;
  }

  get userSettings() {
    return this.environment?.userSettings;
  }
}
