import { Component, Input } from '@angular/core';
import { ISearchFilterItemUpgraded } from '../../models/search-filters.models';

@Component({
  standalone: false,
  selector: 'selected-filter-rating-dep',
  templateUrl: './selected-filter-rating.component.html',
})
export class SelectedFilterRatingDepComponent {
  @Input() item: ISearchFilterItemUpgraded;
  get range(): number[] {
    return [...Array(5).keys()].map((i) => i + 1);
  }

  toInt(value: string | number): number {
    return typeof value === 'string' ? parseInt(value) : value;
  }
}
