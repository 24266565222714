import { HttpParams } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { StateService, UIRouterGlobals, UrlService } from '@uirouter/core';
import { ISearchFilterTypes, ISearchFilterUpgraded } from '../models/search-filters.models';

@Component({
  standalone: false,
  selector: 'search-mobile-action-panel-dep',
  templateUrl: './search-mobile-action-panel.component.html',
})
export class SearchMobileActionPanelDepComponent {
  static readonly selector = 'searchMobileActionPanel';
  @Input() filters: ISearchFilterTypes;
  @Input() hideExport?: boolean;
  @Input() viewMode?: string;
  @Input() customFilters?: boolean;

  constructor(
    private urlService: UrlService,
    private stateService: StateService,
    private activeState: UIRouterGlobals,
    private window: Window,
  ) {}

  chooseFilters() {
    const params = Object.assign(
      {
        returnState: this.activeState.current.name || 'main.search',
      },
      this.urlService.search(),
    );

    if (this.customFilters) {
      Object.assign(params, {
        customFilters: this.filters.ui
          .filter((filter: ISearchFilterUpgraded) => filter.visible)
          .map((filter: ISearchFilterUpgraded) => filter.name),
      });
    }

    this.stateService.go('edit.searchFilters', params);
  }

  exportSearchResult() {
    let params = new HttpParams();

    params = params.appendAll(this.urlService.search());
    this.window.open('/a/search/export/?' + params);
  }
}
