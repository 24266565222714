export class OlarkClient {
  constructor(private olarkCode: string) {}

  setup() {
    if (this.olarkCode) {
      (function (o, l, a, r, k, y) {
        if (o.olark) {
          return;
        }
        r = 'script';
        y = l.createElement(r);
        r = l.getElementsByTagName(r)[0];
        y.async = true;
        y.src = '//' + a;
        r.parentNode.insertBefore(y, r);
        y = o.olark = function () {
          k.s.push(arguments);
          k.t.push(+new Date());
        };
        y.extend = function (i, j) {
          y('extend', i, j);
        };
        y.identify = function (i) {
          y('identify', (k.i = i));
        };
        y.configure = function (i, j) {
          y('configure', i, j);
          k.c[i] = j;
        };
        k = y._ = { s: [], t: [+new Date()], c: {}, l: a };
      })(<any>window, document, 'static.olark.com/jsclient/loader.js');

      /* Add configuration calls below this comment */
      (<any>window).olark.identify(this.olarkCode);
      (<any>window).olark.configure('system.hb_position', 'right');
      (<any>window).olark.configure('system.hb_primary_color', '#1971c2');
      (<any>window).olark.configure('system.hb_dark_theme', true);
    }
  }
}
