import 'ajs/components/_init.ts';
import 'ajs/components/forms/autodisable-directive.ts';
import 'ajs/components/forms/autofocus-directive.ts';
import 'ajs/components/forms/focus-first-invalid-input-directive.ts';
import 'ajs/components/forms/focus-first-form-control-directive.ts';
import 'ajs/components/forms/autofill-fix-directive.ts';
import 'ajs/components/forms/auto-tab-directive.ts';
import 'ajs/components/forms/report-model-completeness-directive.ts';
import 'ajs/components/forms/form-service.ts';
import 'ajs/components/forms/yesno-value-directive.ts';
import 'ajs/components/forms/scroll-service.ts';
import 'ajs/components/forms/scroll-to-directive.ts';
import 'ajs/components/forms/scroll-to-input-directive.ts';
import 'ajs/components/forms/scroll-on-event-directive.ts';
import 'ajs/components/forms/submit-on-directive.ts';
import 'ajs/components/forms/toggle-button-component.ts';
import 'ajs/components/forms/click-confirmation-directive.ts';
import 'ajs/components/forms/copy-link-component.ts';
import 'ajs/components/forms/when-scrolled-directive.ts';
import 'ajs/components/forms/touch_click.ts';
import 'ajs/components/forms/track-link-click-directive.ts';
import 'ajs/components/forms/required-form-field';
import 'ajs/components/file-upload/file-upload-component.ts';
import 'ajs/components/file-upload/single-file-upload-component.ts';
import 'ajs/components/file-upload/file-input-directive.ts';
import 'ajs/components/file-upload/file-upload-directive.ts';
import 'ajs/components/file-upload/file-upload-service.ts';
import 'ajs/components/file-upload/upload-options.ts';
import 'ajs/components/attributes/attributes-directive.ts';
import 'ajs/components/attributes/attribute-info-component.ts';
import 'ajs/components/attributes/unique-attribute-value-directive.ts';
import 'ajs/components/attributes/validate-attribute-value-directive.ts';
import 'ajs/components/attributes/attributes-service.ts';
import 'ajs/components/dates/date-format-directive.ts';
import 'ajs/components/dates/datepicker-popup.ts';
import 'ajs/components/dates/datetime-service.ts';
import 'ajs/components/dates/date-time-component.ts';
import 'ajs/components/dates/time-indicator-component.ts';
import 'ajs/components/dates/date-with-hidden-time-component.ts';
import 'ajs/components/dates/time-zone-indicator-component.ts';
import 'ajs/components/dates/date-selector-directive.ts';
import 'ajs/components/edit_box/edit-box-directive.ts';
import 'ajs/components/filters/html-to-plain-text.ts';
import 'ajs/components/filters/int-range-filter.ts';
import 'ajs/components/filters/trim-string-filter.ts';
import 'ajs/components/filters/semicolon-join-filter.ts';
import 'ajs/components/filters/unsafe-path-filter.ts';
import 'ajs/components/filters/course-hours-formatter-filter.ts';
import 'ajs/components/filters/titlecase-filter.ts';
import 'ajs/components/formatted-digit-component.ts';
import 'ajs/components/filters/yesno-filter.ts';
import 'ajs/components/iframe-onload-directive.ts';
import 'ajs/components/html-compile.ts';
import 'ajs/components/markdown/markdown-editor-directive.ts';
import 'ajs/components/markdown/markdown-editor-component.upgradable.ts';
import 'ajs/components/markdown/markdown-service.ts';
import 'ajs/components/markdown/markdown-filter.ts';
import 'ajs/components/modal-directive.ts';
import 'ajs/components/modal-text/modal-text-component.ts';
import 'ajs/components/empty-menu-items-directive.ts';
import 'ajs/components/rotator-bar-directive.ts';
import 'ajs/components/requests/cached.ts';
import 'ajs/components/requests/shared.ts';
import 'ajs/components/validation/email-required-directive.ts';
import 'ajs/components/validation/unique-email-directive.ts';
import 'ajs/components/validation/unique-login-directive.ts';
import 'ajs/components/validation/validate-hash-code-directive.ts';
import 'ajs/components/validation/valid-datetime-directive.ts';
import 'ajs/components/validation/valid-percentage-directive.ts';
import 'ajs/components/validation/valid-positive-float-directive.ts';
import 'ajs/components/validation/valid-positive-integer-directive.ts';
import 'ajs/components/validation/valid-word-directive.ts';
import 'ajs/components/validation/validate-equality-directive.ts';
import 'ajs/components/validation/validate-inequality-directive.ts';
import 'ajs/components/validation/handle-array-change-directive.ts';
import 'ajs/components/validation/handle-object-change-directive.ts';
import 'ajs/components/validation/mutual-exclusivity-directive.ts';
import 'ajs/components/validation/required-if-model-saved-directive.ts';
import 'ajs/components/validation/valid-npi-number-directive.ts';
import 'ajs/components/validation/valid-range-directive';
import 'ajs/components/thumbnail.ts';
import 'ajs/components/expand-for-more-directive.ts';
import 'ajs/components/expand-for-more-component.upgradable.ts';
import 'ajs/components/dates/model-to-date-directive.ts';
import 'ajs/components/emails/emails-list-directive.ts';
import 'ajs/components/emails/email-attachments-directive.ts';
import 'ajs/components/tree-nodes-service.ts';
import 'ajs/components/phone-component.ts';
import 'ajs/components/score-component.ts';
import 'ajs/components/scroll-observer.ts';
import 'ajs/components/texts/read-time/read-time-component.ts';
