import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { EditInstructorComponent } from 'modules/admin/instructors/edit/edit-instructor.component';
import { InstructorsService } from 'modules/instructors/services/instructor.service';

declare const angular: angular.IAngularStatic;

export default angular
  .module('kmi.lms.core')
  .factory('Instructor', downgradeInjectable(InstructorsService))
  .directive('instructorEditComponent', downgradeComponent({ component: EditInstructorComponent }));
