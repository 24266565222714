// Upgraded from app/modules/app/user_settings/user-settings-service.ts
import { FactoryProvider } from '@angular/core';

export abstract class UserSettingsService {
  abstract get user_network_enabled(): boolean;
}

export function userSettingsServiceFactory(injector: angular.auto.IInjectorService): UserSettingsService {
  return injector.get<UserSettingsService>('userSettingsService');
}

export const userSettingsServiceProvider: FactoryProvider = {
  provide: UserSettingsService,
  useFactory: userSettingsServiceFactory,
  deps: ['$injector'],
};
