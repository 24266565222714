import { FactoryProvider } from '@angular/core';

export type markdownRender = (value: string) => string | null;

export class MarkdownRenderService {
  private md: markdownRender;

  constructor(md: markdownRender) {
    this.md = md;
  }

  render(value: string): string {
    return this.md(value);
  }
}

export function markdownRenderServiceFactory(injector: angular.auto.IInjectorService): MarkdownRenderService {
  const render = injector.get<markdownRender>('markdownRender');

  return new MarkdownRenderService(render);
}

export const markdownRenderServiceProvider: FactoryProvider = {
  provide: MarkdownRenderService,
  useFactory: markdownRenderServiceFactory,
  deps: ['$injector'],
};
