import { FactoryProvider } from '@angular/core';
import { ISearchFilterOptionsService, ISearchFilterOptionsUpgraded } from '../../models/search-filters.models';

export abstract class UserFilterService {
  abstract getGroupFilter(): ISearchFilterOptionsService;
  abstract getRoleFilter(options?: Partial<ISearchFilterOptionsUpgraded>): ISearchFilterOptionsService;
  abstract getIncludeSubgroupsFilter(options?: Partial<ISearchFilterOptionsUpgraded>): ISearchFilterOptionsService;
}

export function searchUserFilterFactory(injector: angular.auto.IInjectorService): ISearchFilterOptionsService {
  return injector.get<ISearchFilterOptionsService>('searchUserFilters');
}

export const searchUserFilterProvider: FactoryProvider = {
  provide: UserFilterService,
  useFactory: searchUserFilterFactory,
  deps: ['$injector'],
};
