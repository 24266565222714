import { FactoryProvider } from '@angular/core';

export abstract class SecurityService {
  isStateAvailable: (stateName: string, obj?: any) => boolean;
  isPageAvailable: (options: any, obj?: any) => boolean;
}

export function securityServiceFactory(injector: angular.auto.IInjectorService): SecurityService {
  return injector.get<SecurityService>('securityService');
}

export const securityServiceProvider: FactoryProvider = {
  provide: SecurityService,
  useFactory: securityServiceFactory,
  deps: ['$injector'],
};
