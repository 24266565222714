(function () {
  angular
    .module('globalDependencies', [])
    .factory('_', function () {
      return (<any>window)._;
    })
    .factory('moment', function () {
      return (<any>window).moment;
    })
    .factory('sjcl', function () {
      return require('sjcl');
    })
    // .factory('elms', function () {
    //   return (<any>window).elms;
    // })
    .factory('uuid', function () {
      return require('uuid');
    })
    .factory('Class', function () {
      return (<any>window).Class;
    })
    .factory('markdownRender', function (globalConfig, _) {
      const md = require('markdown-it')({
        html: !globalConfig.markdown.sanitize,
        linkify: true,
        typographer: true,
      })
        .disable('smartquotes')
        .use(require('markdown-it-attrs'))
        .use(require('markdown-it-anchor/dist/markdownItAnchor'), {});

      md.use(require('markdown-it-sup')).use(require('markdown-it-sub'));

      // Remember old renderer, if overriden, or proxy to default renderer
      var defaultRender =
        md.renderer.rules.link_open ||
        function (tokens, idx, options, env, self) {
          return self.renderToken(tokens, idx, options);
        };

      // Override all links to open it in a new window
      md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
        // If you are sure other plugins can't add `target` - drop check below
        var aIndex = tokens[idx].attrIndex('target');

        var hrefAttrIndex = tokens[idx].attrIndex('href');
        if (hrefAttrIndex > -1) {
          if (
            _.startsWith(tokens[idx].attrs[hrefAttrIndex][1], '#') ||
            _.startsWith(tokens[idx].attrs[hrefAttrIndex][1], '/')
          ) {
            return defaultRender(tokens, idx, options, env, self);
          }
        }

        if (aIndex < 0) {
          tokens[idx].attrPush(['target', '_blank']); // add new attribute
        } else {
          tokens[idx].attrs[aIndex][1] = '_blank'; // replace value of existing attr
        }

        // pass token to default renderer.
        return defaultRender(tokens, idx, options, env, self);
      };

      return function (val) {
        return md.render(val);
      };
    })
    .factory('markdownRenderHtml', function (_) {
      const md = require('markdown-it')({
        html: true,
        linkify: true,
        typographer: true,
      })
        .use(require('markdown-it-attrs'))
        .use(require('markdown-it-anchor/dist/markdownItAnchor'), {});

      md.use(require('markdown-it-sup')).use(require('markdown-it-sub'));

      // Remember old renderer, if overriden, or proxy to default renderer
      var defaultRender =
        md.renderer.rules.link_open ||
        function (tokens, idx, options, env, self) {
          return self.renderToken(tokens, idx, options);
        };

      // Override all links to open it in a new window
      md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
        // If you are sure other plugins can't add `target` - drop check below
        var aIndex = tokens[idx].attrIndex('target');

        var hrefAttrIndex = tokens[idx].attrIndex('href');
        if (hrefAttrIndex > -1) {
          if (
            _.startsWith(tokens[idx].attrs[hrefAttrIndex][1], '#') ||
            _.startsWith(tokens[idx].attrs[hrefAttrIndex][1], '/')
          ) {
            return defaultRender(tokens, idx, options, env, self);
          }
        }

        if (aIndex < 0) {
          tokens[idx].attrPush(['target', '_blank']); // add new attribute
        } else {
          tokens[idx].attrs[aIndex][1] = '_blank'; // replace value of existing attr
        }

        // pass token to default renderer.
        return defaultRender(tokens, idx, options, env, self);
      };

      return function (val) {
        return md.render(val);
      };
    });
})();
