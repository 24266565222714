import { HttpResponse } from '@angular/common/http';

export enum TTL {
  NO_CACHE = 0,
  NEVER_EXPIRE = -1,
}

export interface IHttpCacheModel {
  url: string;
  response: HttpResponse<any>;
  entryTime: number;
  ttl: TTL | number;
  name: string;
}

export interface IHttpCacheMapContext {
  name: string;
  ttl: TTL | number;
}
