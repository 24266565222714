<div *ngIf="filter.visible">
  <div ngbAccordion *ngIf="!fullScreen">
    <div ngbAccordionItem [collapsed]="!isOpen">
      <div ngbAccordionHeader>
        <button type="button" ngbAccordionButton>
          <span class="pull-right fa fa-fw fa-caret-down"></span>
          <span>{{ filter.label }}</span>
        </button>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <input
              type="number"
              class="form-control"
              min="1"
              placeholder="Miles"
              [ngModelOptions]="{ updateOn: 'blur' }"
              #numberValue="ngModel"
              [(ngModel)]="filter.value"
              (keydown.enter)="blur($event)"
              (ngModelChange)="valueChanged(filter.value, filter.term)" />
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="fullScreen">
    <ul class="content-list">
      <li class="content-list-item search-item">
        <div class="form-group number-item">
          <input
            type="number"
            class="form-control"
            min="1"
            placeholder="Miles"
            [ngModelOptions]="{ updateOn: 'blur' }"
            [(ngModel)]="filter.value"
            (keydown.enter)="valueChanged(filter.value, filter.term)"
            (change)="valueChanged(filter.value, filter.term)" />
        </div>
      </li>
    </ul>
  </div>
</div>
