import { FormControl, Validators } from '@angular/forms';
import moment from 'moment';
import 'moment-duration-format';
import 'moment-timezone';
import { Observable } from 'rxjs';

export class ElmsUtils {
  static formatDuration(seconds: number): string | null {
    return seconds ? moment.duration(seconds, 'seconds').format('h[h] m[m] s[s]') : null;
  }

  static duration(value: number, format: moment.unitOfTime.DurationConstructor): moment.Duration {
    return moment.duration(value, format);
  }

  static isBefore(date1: Date | string, date2: Date | string): boolean {
    return moment(date1).isBefore(date2);
  }

  static formatTime(date: Date | string): string {
    return moment(date).format('hh:mm:ss a');
  }

  static formatDate(date: Date | string, format = 'MMM DD, YYYY'): string {
    return moment(date).format(format);
  }

  static formatDateAsTz(date: Date | string, timeZone: string, format = 'MMM DD, YYYY'): string {
    return moment(date).tz(timeZone).format(format);
  }

  static formatPrice(value: number): string {
    if (value && !isNaN(value)) {
      return '$' + (Math.round(value * 100) / 100).toFixed(2);
    }

    return 'Free';
  }

  static formatDigit(value: number | null, fractionDigits?: number, keepTrailingZeros?: boolean): string | null {
    if ((value || value === 0) && !isNaN(value)) {
      if (value === 0) {
        return '0';
      }

      const digits = fractionDigits || 2;
      const result = Math.round(value * Math.pow(10, digits)) / Math.pow(10, digits);

      if (keepTrailingZeros) {
        return String(result % 1 > 0 ? result.toFixed(digits) : result);
      }

      return parseFloat(result.toFixed(digits)).toString();
    }

    return null;
  }

  static escapeRegExp(str: string): string | null {
    if (!str) {
      return null;
    }

    return str.replace(/[\\-\\[\]\\/\\{\\}\\(\\)\\*\\+\\?\\.\\\\^\\$\\|]/g, '\\$&');
  }

  static escapeHtml(htmlStr: string, quote?: boolean): string {
    if (!htmlStr) {
      return null;
    }

    let html = htmlStr.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');

    if (quote) {
      html = htmlStr.replace(/"/g, '&quot;').replace(/'/g, '&#39;');
    }

    return html;
  }

  static replaceHTMLEntities(str: string) {
    const htmlEntities = {
      '&amp;': '&',
      '&lt;': '<',
      '&gt;': '>',
      '&quot;': '"',
      '&#039;': "'",
      '&ndash;': '-',
    };

    return str.replace(/&[\w#]+;/g, (entity) => {
      return htmlEntities[entity] || entity;
    });
  }

  static htmlToPlainText(value: string, spaceDelimiter?: boolean): string {
    return this.replaceHTMLEntities(this.replaceHTMLTags(value, spaceDelimiter));
  }

  static replaceHTMLTags(value: string, spaceDelimiter?: boolean): string {
    if (spaceDelimiter) {
      return value?.replace(/<[^>]+>/gm, '') || '';
    }

    return (value?.replace(/<[^>]+>/gm, ' ') || '').replace(/ +/g, ' ');
  }

  static formatBytes(bytes: number, decimals?: number): string {
    if (bytes === 0) {
      return '0 Bytes';
    }

    const k = 1000;
    const dm = decimals + 1 || 3;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return (bytes / Math.pow(k, i)).toPrecision(dm) + ' ' + sizes[i];
  }

  static populateTimes(): string[] {
    const quarterHours = ['00', '15', '30', '45'];
    const times = [];

    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 4; j++) {
        let time = i + ':' + quarterHours[j];

        if (i < 10) {
          time = '0' + time;
        }

        times.push(time);
      }
    }

    return times;
  }

  static validEmail(email: string): boolean {
    return !Validators.email(new FormControl(email));
  }

  static saveAs(blob: Blob, fileName: string): void {
    if (window.navigator['msSaveOrOpenBlob']) {
      window.navigator['msSaveOrOpenBlob'](blob, fileName);
    } else {
      const a = document.createElement('a');
      const url = URL.createObjectURL(blob);

      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);

      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }

  static getContentDispositionFilename(headers: string): string | null {
    if (headers) {
      const result = RegExp('filename="(.+)"').exec(headers);

      return result !== null ? result[1] : null;
    }

    return null;
  }

  static formatUrl(urlTemplate: string, params: Record<string, string | number | null>): string {
    const keys = Object.keys(params);

    if (keys.length) {
      const re = new RegExp(`:(?<groupId>${keys.join('|')})`, 'g');

      return urlTemplate
        .replace(re, (_, groupId: string) => (params[groupId] ? params[groupId].toString() : ''))
        .replace(/([^:]\/)\/+/g, '$1');
    }

    return urlTemplate;
  }

  static copyToClipboard(text: string): Observable<void> {
    return new Observable<void>((observer) => {
      const fallbackCopyTextToClipboard = () => {
        const selBox = document.createElement('textarea');

        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = text;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        observer.next();
      };

      if (window.navigator.clipboard) {
        window.navigator.clipboard
          .writeText(text)
          .then(() => observer.next())
          .catch(() => fallbackCopyTextToClipboard());
      } else {
        fallbackCopyTextToClipboard();
      }
    });
  }

  static sanitizeFilename(input: string): string {
    // Remove invalid filename characters manually
    input = input.replace(/[<>:"/\\|?*]/g, ''); // Removed unnecessary escape before /

    // Replace spaces with underscores
    input = input.replace(/\s+/g, '_');

    // Remove control characters (ASCII < 32 and 127-159)
    input = [...input] // Convert to array to filter characters
      .filter((char) => char.charCodeAt(0) >= 32 && (char.charCodeAt(0) < 127 || char.charCodeAt(0) > 159))
      .join(''); // Convert back to string

    return input.trim();
  }
}
