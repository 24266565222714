import { downgradeComponent } from '@angular/upgrade/static';
import { RecentBlogPostsComponent } from 'modules/blog/components/lists/recent-blog-posts.component';
import { BlogPostsRotatorComponent } from 'modules/blog/components/rotator/blog-posts-rotator.component';
import { RecentBlogPostHeroComponent } from 'modules/blog/components/rotator/recent-blog-post-hero.component';
import { RecentBlogPostComponent } from 'modules/blog/components/rotator/recent-blog-post.component';
import { BlogPostListTileComponent } from 'modules/blog/components/view/blog-post-list-tile.component';
import { ChannelPostsTileComponent } from '../../../modules/blog/components/lists/channel-posts-tile.component';

declare const angular: angular.IAngularStatic;

export default angular
  .module('kmi.lms.core')
  .directive(
    ChannelPostsTileComponent.selector,
    downgradeComponent({ component: ChannelPostsTileComponent }) as angular.IDirectiveFactory,
  )
  .directive(
    RecentBlogPostsComponent.selector,
    downgradeComponent({ component: RecentBlogPostsComponent }) as angular.IDirectiveFactory,
  )
  .directive(
    BlogPostsRotatorComponent.selector,
    downgradeComponent({ component: BlogPostsRotatorComponent }) as angular.IDirectiveFactory,
  )
  .directive(
    RecentBlogPostHeroComponent.selector,
    downgradeComponent({ component: RecentBlogPostHeroComponent }) as angular.IDirectiveFactory,
  )
  .directive(
    BlogPostListTileComponent.selector,
    downgradeComponent({ component: BlogPostListTileComponent }) as angular.IDirectiveFactory,
  )
  .directive(
    RecentBlogPostComponent.selector,
    downgradeComponent({ component: RecentBlogPostComponent }) as angular.IDirectiveFactory,
  );
