export class OneTrustCookieManager {
  constructor(private oneTrustCookieId: string) {}

  setup() {
    if (!this.oneTrustCookieId) {
      return;
    }

    const headTag = document.getElementsByTagName('head')[0];

    (<any>window).acnSetUserGeo = function (locationJson) {
      (<any>window).otUserLocation = locationJson.country;
    };

    function getCookie(name) {
      const value = '; ' + document.cookie;
      const parts = value.split('; ' + name + '=');
      if (parts.length === 2) {
        return parts.pop().split(';').shift();
      }
    }

    const oneTrustGeoScript = document.createElement('script');
    oneTrustGeoScript.type = 'text/javascript';
    oneTrustGeoScript.src = 'https://geolocation.onetrust.com/cookieconsentpub/v1/geo/location/acnSetUserGeo';
    oneTrustGeoScript.charset = 'UTF-8';
    oneTrustGeoScript.async = false;
    headTag.appendChild(oneTrustGeoScript);

    const oneTrustCookieScript = document.createElement('script');
    oneTrustCookieScript.type = 'text/javascript';
    oneTrustCookieScript.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    oneTrustCookieScript.charset = 'UTF-8';
    oneTrustCookieScript.async = false;
    oneTrustCookieScript.setAttribute('data-domain-script', this.oneTrustCookieId);
    headTag.appendChild(oneTrustCookieScript);

    (<any>window).OptanonWrapper = function () {};

    // check if sat_track cookie exists
    const check = getCookie('sat_track');

    if (check === undefined) {
      // list of geos where only strictly necessary cookies are allowed
      const SNGeos = ['GB', 'BE', 'IE', 'FR', 'RU'];
      if (SNGeos.indexOf((<any>window).otUserLocation) !== -1) {
        document.cookie = 'sat_track=false;path=/;secure;max-age=31536000';
      } else {
        document.cookie = 'sat_track=true;path=/;secure;max-age=31536000';
      }
    }

    //check if the banner has been closed already
    const minimizeBanner = getCookie('OptanonAlertBoxClosed');
    let localStorageName = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    const substringStart = localStorageName.lastIndexOf('/') + 1;
    const substringEnd = localStorageName.lastIndexOf('.');
    localStorageName = localStorageName.substr(substringStart, substringEnd);
    localStorageName = localStorageName.replace(/-/g, '');
    const minimizeButtonKey = localStorageName;
    //if not closed yet delegate event to the buttons
    if (minimizeBanner === undefined) {
      (<any>window).$(document).one('click', 'button[class*="save-preference"], button[id*="accept"]', function () {
        const minimizeButtonText = (<any>window).$('button#onetrust-pc-btn-handler').text();
        localStorage.setItem(minimizeButtonKey, minimizeButtonText);
        (<any>window)
          .$('body')
          .append(
            '<div id="optanon-minimize-wrapper" data-analytics-template-zone="consent manager" data-analytics-module-name="consent manager" class="optanon-toggle-display"><button id="optanon-minimize-button" title="Cookies Settings" data-analytics-link-name="cookies settings" data-analytics-content-type="cta" aria-label="Cookies Settings">Cookies Settings</button></div>',
          );
      });
      (<any>window).$(document).on('click', '.optanon-toggle-display', function () {
        (<any>window).Optanon.ToggleInfoDisplay(); // eslint-disable-line new-cap
      });
    } else {
      //show the minimized button if the banner is closed already
      (<any>window).$(function () {
        // var optanondatalink = (localStorage.getItem(minimizeButtonKey) !== null) ? localStorage.getItem(minimizeButtonKey) : '';
        (<any>window)
          .$('body')
          .append(
            '<div id="optanon-minimize-wrapper" data-analytics-template-zone="consent manager" data-analytics-module-name="consent manager" class="optanon-toggle-display"><button id="optanon-minimize-button" title="Cookies Settings" data-analytics-link-name="cookies settings" data-analytics-content-type="cta" aria-label="Cookies Settings">Cookies Settings</button></div>',
          );
      });
    }
  }
}
