import 'ajs/modules/app/constants.ts';
import 'ajs/components';

import 'ajs/modules/home/_init.ts';
import 'ajs/modules/home/general-home-page-component.ts';
import 'ajs/modules/integration/_init.ts';
import 'ajs/modules/integration/video/vimeo/vimeo-service.ts';
import 'ajs/modules/integration/video/limelight/limelight-service.ts';
import 'ajs/modules/integration/video/video-provider.ts';
import 'ajs/modules/app/csrf-protection-service.ts';
import 'ajs/modules/training-category/_init.ts';
import 'ajs/modules/training-category/common/training-category.ts';
import 'ajs/modules/app/access-denied-component.ts';
import 'ajs/modules/app/static-pages-component.ts';
import 'ajs/modules/app/api-url-service.ts';
import 'ajs/modules/app/back-url-service.ts';
import 'ajs/modules/app/files/file-service.ts';
import 'ajs/modules/app/authorization/modal/auth-ticket-compliance-error-component.ts';
import 'ajs/modules/app/authorization/elms-auth-factory.ts';
import 'ajs/modules/app/authorization/http-request-authentication-service.ts';
import 'ajs/modules/app/authorization/http-request-permit-service.ts';
import 'ajs/modules/app/caching/http-request-caching-service.ts';
import 'ajs/modules/app/handle-search-submit-directive.ts';
import 'ajs/modules/authorization/requirements/requirements-service.ts';
import 'ajs/modules/authorization/requirements/requirements-component.ts';
import 'ajs/modules/authorization/service.ts';
import 'ajs/modules/app/environment/http-request-status-service.ts';
import 'ajs/modules/app/environment/lms-integrity.ts';
import 'ajs/modules/app/environment/notification-factory.ts';
import 'ajs/modules/app/event-dispatcher.ts';
import 'ajs/modules/app/global-service.ts';
import 'ajs/modules/app/lms/lms-header-title-directive.ts';
import 'ajs/modules/app/lms/lms-meta-directive.ts';
import 'ajs/modules/app/lms/lms-meta-property-directive.ts';
import 'ajs/modules/app/lms/lms-wcag-level-directive.ts';
import 'ajs/modules/app/lms/lms-notifications-component.ts';
import 'ajs/modules/app/lms/lms-main-container-component.ts';
import 'ajs/modules/app/location-history-service.ts';
import 'ajs/modules/app/models/announcement.ts';
import 'ajs/modules/app/models/profile.ts';
import 'ajs/modules/groups/common/group-resource.ts';
import 'ajs/modules/app/models/user/user.ts';
import 'ajs/modules/app/navigation/components/main-navigation-menu-items-component.ts';
import 'ajs/modules/app/navigation/components/portal-logo-component.ts';
import 'ajs/modules/app/navigation/components/main-portal-actions-component.ts';
import 'ajs/modules/app/navigation/components/general-navigation-component.ts';
import 'ajs/modules/app/navigation/components/print-navigation-component.ts';
import 'ajs/modules/app/navigation/drawer-service.ts';
import 'ajs/modules/app/navigation/menu-service.ts';
import 'ajs/modules/app/navigation/active-menu-item-title-directive.ts';
import 'ajs/modules/app/navigation/navigation-service.ts';
import 'ajs/modules/app/navigation/security-service.ts';
import 'ajs/modules/app/navigation/url-router-default-view-factory.ts';
import 'ajs/modules/app/navigation/url-version-service.ts';
import 'ajs/modules/app/navigation/url-version-filter.ts';
import 'ajs/modules/app/page-view/general-footer-component.ts';
import 'ajs/modules/app/policies-component.ts';
import 'ajs/modules/app/policies-service.ts';
import 'ajs/modules/app/prompt-view-component.ts';
import 'ajs/modules/app/server-error-handler.ts';
import 'ajs/modules/app/settings/settings-service.ts';
import 'ajs/modules/app/settings/site-settings-service.ts';
import 'ajs/modules/app/settings/wcag-level-service.ts';
import 'ajs/modules/app/user_settings/user-settings-service.ts';
import 'ajs/modules/app/user_settings/user-group-branding-service.ts';
import 'ajs/modules/app/seo/ga-factory.ts';
import 'ajs/modules/app/seo/meta-ogp-service.ts';
import 'ajs/modules/app/seo/meta-service.ts';
import 'ajs/modules/app/settings/custom-title-service.ts';
import 'ajs/modules/app/settings/mandatory-label-component.ts';
import 'ajs/modules/app/portals/_init.ts';
import 'ajs/modules/app/portals/portals-service.ts';
import 'ajs/modules/entities/entity-details-component.ts';
import 'ajs/modules/entities/entity-data-service.ts';
import 'ajs/modules/entities/entity-author-component.ts';
import 'ajs/modules/certificates/_init.ts';
import 'ajs/modules/certificates/common/certificate-resource.ts';
import 'ajs/modules/course/_init.ts';
import 'ajs/modules/course/common/_init.ts';
import 'ajs/modules/course/common/course.ts';
import 'ajs/modules/course/common/coupon-code.ts';
import 'ajs/modules/course/common/coupon-code-availability.ts';
import 'ajs/modules/course/common/course-components-service.ts';
import 'ajs/modules/course/common/course-accreditations-service.ts';
import 'ajs/modules/course/common/enums-const.ts';
import 'ajs/modules/course/common/media-service.ts';
import 'ajs/modules/course/common/purchase.ts';
import 'ajs/modules/course/common/recommend-course-component.ts';
import 'ajs/modules/course/common/review-service.ts';
import 'ajs/modules/course/common/course-details-config.ts';
import 'ajs/modules/course/common/course-details-state-service.ts';
import 'ajs/modules/course/common/base-course-details-component.ts';
import 'ajs/modules/course/common/collections-service.ts';
import 'ajs/modules/course/common/edit-actions-dropdown-directive.ts';
import 'ajs/modules/course/common/course-edit-actions-component.ts';
import 'ajs/modules/course/common/course-quiz-types-service.ts';
import 'ajs/modules/course/common/collection-component-resource.ts';
import 'ajs/modules/course/common/course-publish-date-component.ts';
import 'ajs/modules/course/common/session-time-badge-component.ts';
import 'ajs/modules/course/common/session-tags-component.ts';
import 'ajs/modules/course/custom_list/_init.ts';
import 'ajs/modules/course/custom_list/custom-course-lists-service.ts';
import 'ajs/modules/course/common/competencies/course-competencies-component.ts';
import 'ajs/modules/course/common/competencies/course-competency-level-nodes-component.ts';
import 'ajs/modules/course/common/competencies/course-competencies-service.ts';
import 'ajs/modules/course/views/_init.ts';
import 'ajs/modules/course/views/course-views-config.ts';
import 'ajs/modules/course/views/components/modern/also-registered-for-directive.ts';
import 'ajs/modules/course/views/course-details-base-controller.ts';
import 'ajs/modules/course/views/modern-course-details-component.ts';
import 'ajs/modules/course/views/formats/collection/components/collection-structure-layer-component.ts';
import 'ajs/modules/course/views/formats/collection/collection-details-component.ts';
import 'ajs/modules/course/views/formats/collection/generic-collection-details-component.ts';
import 'ajs/modules/course/views/formats/collection/conference/conference-details-component.ts';
import 'ajs/modules/course/views/formats/collection/conference/conference-details-service.ts';
import 'ajs/modules/course/views/printable/course-printable-schedule-component.ts';
import 'ajs/modules/course/views/printable/components/conference-printable-sessions.ts';
import 'ajs/modules/course/views/printable/components/conference-printable-details.ts';
import 'ajs/modules/course/views/printable/components/conference-printable-location-components.ts';
import 'ajs/modules/course/views/formats/collection/conference/components/session-alerts-component.ts';
import 'ajs/modules/course/views/formats/collection/conference/components/session-certificate-component.ts';
import 'ajs/modules/course/views/formats/collection/conference/components/credits/session-credits-component.ts';
import 'ajs/modules/course/views/formats/collection/conference/components/session-registration-control-component.ts';
import 'ajs/modules/course/views/formats/collection/conference/components/session-venue-component.ts';
import 'ajs/modules/course/views/formats/collection/conference/components/session-ways-of-attendance-component.ts';
import 'ajs/modules/course/views/formats/collection/exam-track/exam-track-details-component.ts';
import 'ajs/modules/course/views/formats/live-event/components/session-details-directive.ts';
import 'ajs/modules/course/views/formats/live-event/components/session-registration-actions-directive.ts';
import 'ajs/modules/course/views/formats/live-event/live-event-details-component.ts';
import 'ajs/modules/course/views/formats/course-specific-format-details-component.ts';
import 'ajs/modules/course/views/formats/online/article/article-audio-component.ts';
import 'ajs/modules/course/views/formats/online/article/article-details-component.ts';
import 'ajs/modules/course/views/formats/online/article/article-content-component.ts';
import 'ajs/modules/course/views/formats/online/online-course-format-details-component.ts';
import 'ajs/modules/course/views/components/generic-controllers/related-collections.ts';
import 'ajs/modules/course/views/components/generic-controllers/detailed-info-controller.ts';
import 'ajs/modules/course/views/components/course-price-info-directive.ts';
import 'ajs/modules/course/views/components/course-presenters-component.ts';
import 'ajs/modules/course/views/components/course-multiple-prices-info-directive.ts';
import 'ajs/modules/course/views/components/modern/detailed-info-directive.ts';
import 'ajs/modules/course/views/components/modern/common-info-directive.ts';
import 'ajs/modules/course/views/components/modern/common-info-hero-directive.ts';
import 'ajs/modules/course/views/components/modern/course-credits-component.ts';
import 'ajs/modules/course/views/components/modern/course-description-hero-component.ts';
import 'ajs/modules/course/views/components/modern/related-collections.ts';
import 'ajs/modules/course/views/components/modern/resources-component.ts';
import 'ajs/modules/course/views/components/modern/prerequisites/collection-prerequisites-component.ts';
import 'ajs/modules/course/views/components/resources-directive.ts';
import 'ajs/modules/course/views/components/related-collections.ts';
import 'ajs/modules/course/views/components/prerequisites/prerequisites-directive.ts';
import 'ajs/modules/course/views/components/prerequisites/prerequisites-list-directive.ts';
import 'ajs/modules/course/views/components/prerequisites/collection-prerequisites-component.ts';
import 'ajs/modules/course/views/components/prerequisites/course-prerequisites-service.ts';
import 'ajs/modules/course/views/components/contacts-directive.ts';
import 'ajs/modules/course/views/components/course-list-info-directive.ts';
import 'ajs/modules/course/views/components/course-tag-list-info-directive.ts';
import 'ajs/modules/course/views/components/subject-areas-list-info-directive.ts';
import 'ajs/modules/course/views/components/target-audiences-list-info-directive.ts';
import 'ajs/modules/course/views/components/training-categories-list-info-component.ts';
import 'ajs/modules/course/views/components/credits/cource-available-credits-component.ts';
import 'ajs/modules/course/views/components/course-details-right-panel-coponent.ts';
import 'ajs/modules/course/views/components/modal/accreditation-statements-modal.ts';
import 'ajs/modules/course/views/components/target-capabilities-directive.ts';
import 'ajs/modules/course/views/components/state-messages/expiration-date-indicator-component.ts';
import 'ajs/modules/course/views/components/state-messages/assessment-intended-for-admin-component.ts';
import 'ajs/modules/course/views/components/state-messages/due-date-indicator-component.ts';
import 'ajs/modules/course/views/components/state-messages/course-registration-register-once-component.ts';
import 'ajs/modules/course/views/components/state-messages/registration-closes-indicator-component.ts';
import 'ajs/modules/course/views/components/state-messages/registration-opens-indicator-component.ts';
import 'ajs/modules/course/views/components/state-messages/state-messages-component.ts';
import 'ajs/modules/course/views/components/state-messages/course-state-messages-service.ts';
import 'ajs/modules/course/views/components/state-messages/target-date-indicator-component.ts';
import 'ajs/modules/course/views/components/header/course-header-component.ts';
import 'ajs/modules/course/views/formats/live-event/components/enrolled-learners/enrolled-learners-component.ts';
import 'ajs/modules/course/views/formats/live-event/components/enrolled-learners/modal/enrolled-learners-modal.ts';
import 'ajs/modules/course/sessions/common/session-location-service.ts';
import 'ajs/modules/course/sessions/common/session-options-service.ts';
import 'ajs/modules/course/sessions/common/course-session-service.ts';
import 'ajs/modules/course/sessions/common/sessions-service.ts';
import 'ajs/modules/course/common/course-service.ts';
import 'ajs/modules/course/common/content-sections-service.ts';
import 'ajs/modules/course/common/play-course-service.ts';
import 'ajs/modules/course/posts/_init.ts';
import 'ajs/modules/course/posts/modern-posts-directive.ts';
import 'ajs/modules/course/posts/base-course-posts-component.ts';
import 'ajs/modules/course/reviews/_init.ts';
import 'ajs/modules/course/reviews/edit/edit-review-direcitve.ts';
import 'ajs/modules/course/reviews/modal/edit-review-window-direcitve.ts';
import 'ajs/modules/course/reviews/modal/edit-review-modal-component.ts';
import 'ajs/modules/course/reviews/reviews-directive.ts';
import 'ajs/modules/course/reviews/modern-reviews-directive.ts';
import 'ajs/modules/course/reviews/base-course-reviews-component.ts';
import 'ajs/modules/course/reviews/graph-directive.ts';
import 'ajs/modules/course/reviews/course-reviews-service.ts';
import 'ajs/modules/course/providers/_init.ts';
import 'ajs/modules/course/providers/common/course-provider-resource.ts';
import 'ajs/modules/course/providers/survey/edit-survey-component.ts';
import 'ajs/modules/course/providers/survey/provider-survey-directive.ts';
import 'ajs/modules/course/providers/organizations/common/organization-member-service.ts';
import 'ajs/modules/course/providers/organizations/common/organization-service.ts';
import 'ajs/modules/course/providers/organizations/view/view-organization-component.ts';
import 'ajs/modules/course/providers/organizations/view/components/organization-settings-view-component.ts';
import 'ajs/modules/course/presenters/_init.ts';
import 'ajs/modules/course/presenters/components/presenter-view-component.ts';
import 'ajs/modules/course/presenters/components/presenter-avatar-component.ts';
import 'ajs/modules/course/presenters/components/presenter-short-view-component.ts';
import 'ajs/modules/course/presenters/common/course-presenter-resource.ts';
import 'ajs/modules/course/presenters/common/presenters-service.ts';
import 'ajs/modules/course/quizzes/_init.ts';
import 'ajs/modules/course/quizzes/result-view-component.ts';
import 'ajs/modules/course/purchase/_init.ts';
import 'ajs/modules/course/purchase/history/course-purchase-history-directive.ts';
import 'ajs/modules/course/purchase/history/course-receipt-component.ts';
import 'ajs/modules/competency/_init.ts';
import 'ajs/modules/credits/_init.ts';
import 'ajs/modules/admin/credits/_init.ts';
import 'ajs/modules/admin/credits/credits-service.ts';
import 'ajs/modules/credits/credit-types-service.ts';
import 'ajs/modules/custom-attributes/common/_init.ts';
import 'ajs/modules/custom-attributes/common/categories-menu-directive.ts';
import 'ajs/modules/custom-attributes/common/category-form-directive.ts';
import 'ajs/modules/custom-attributes/common/plain-form-directive.ts';
import 'ajs/modules/custom-attributes/course/_init.ts';
import 'ajs/modules/custom-attributes/course/custom-attributes-service.ts';
import 'ajs/modules/custom-attributes/session/_init.ts';
import 'ajs/modules/custom-attributes/session/custom-attributes-service.ts';
import 'ajs/modules/custom-attributes/user/_init.ts';
import 'ajs/modules/custom-attributes/user/attributes-service.ts';
import 'ajs/modules/dictionary/_init.ts';
import 'ajs/modules/dictionary/dictionary-component.ts';
import 'ajs/modules/emails/_init.ts';
import 'ajs/modules/emails/common/email-resource.ts';
import 'ajs/modules/emails/common/email-template-resource.ts';
import 'ajs/modules/emails/common/email-template-service.ts';
// import 'ajs/modules/emails/email-service.ts';
// import 'ajs/modules/emails/modal/send-email-modal-component.ts';
import 'ajs/modules/ecommerce/common/_init.ts';
import 'ajs/modules/ecommerce/common/purchase.ts';
import 'ajs/modules/learning_series/_init.ts';
import 'ajs/modules/learning_series/common/resource.ts';
import 'ajs/modules/learning_series/common/learning-series-service.ts';
import 'ajs/modules/learning_series/view/ls-component.ts';
import 'ajs/modules/learning_series/components/section-requirement-type.ts';
import 'ajs/modules/login/components/_init.ts';
import 'ajs/modules/login/_init.ts';
import 'ajs/modules/login/common/user-password-service.ts';
import 'ajs/modules/course/registrations/_init.ts';
import 'ajs/modules/course/registrations/bulk-enrollment/modal/enrollment-session-result-modal-component.ts';
import 'ajs/modules/course/registrations/bulk-enrollment/modal/enrollment-session-confirmation-modal-component.ts';
import 'ajs/modules/course/registrations/view/components/registration-info-component.ts';
import 'ajs/modules/course/registrations/view/components/registration-info-external-component.ts';
import 'ajs/modules/course/registrations/view/components/assessment-result-directive.ts';
import 'ajs/modules/course/registrations/view/formats/format-specific-registration-details-component.ts';
import 'ajs/modules/course/registrations/view/formats/collection/collection-registration-details-component.ts';
import 'ajs/modules/course/registrations/view/formats/collection/generic-collection-registration-details-component.ts';
import 'ajs/modules/course/registrations/view/formats/collection/conference/conference-registration-details-component.ts';
import 'ajs/modules/course/registrations/common/assessment-results-service.ts';
import 'ajs/modules/course/registrations/common/course-registrations-service.ts';
import 'ajs/modules/course/registrations/common/registration.ts';
import 'ajs/modules/course/registrations/common/registration-workflow-service.ts';
import 'ajs/modules/course/registrations/common/registration-workflow-dialog-service.ts';
import 'ajs/modules/course/registrations/common/resource.ts';
import 'ajs/modules/course/registrations/common/registrations-cache.ts';
import 'ajs/modules/course/registrations/common/workflow.ts';
import 'ajs/modules/course/registrations/common/training-service.ts';
import 'ajs/modules/course/registrations/common/statuses.ts';
import 'ajs/modules/course/registrations/common/course-bulk-enrollment-service.ts';
import 'ajs/modules/course/registrations/common/enrollment-session.ts';
import 'ajs/modules/course/registrations/components/manage-course-registration_component.ts';
import 'ajs/modules/course/registrations/components/credit-types-selection-component.ts';
import 'ajs/modules/course/registrations/components/modern/manage-course-registration-component.ts';
import 'ajs/modules/course/registrations/components/modern/course-registrration-alerts-component.ts';
import 'ajs/modules/course/registrations/components/modern/play-course-component.ts';
import 'ajs/modules/course/registrations/components/action-button-container-directive.ts';
import 'ajs/modules/course/registrations/components/dialogs/course-dialog-email-physical-carrier-directive.ts';
import 'ajs/modules/course/registrations/components/dialogs/select-role-component.ts';
import 'ajs/modules/course/registrations/components/registration-actions-directive.ts';
import 'ajs/modules/course/registrations/components/registration-actions-menu-directive.ts';
import 'ajs/modules/course/registrations/components/registration-menu-directive.ts';
import 'ajs/modules/course/registrations/components/registration-verification-indicator-component.ts';
import 'ajs/modules/course/registrations/components/registration-approval-status.ts';
import 'ajs/modules/course/registrations/components/action-button-directive.ts';
import 'ajs/modules/course/registrations/components/registration-action-menu-visible-directive.ts';
import 'ajs/modules/course/registrations/components/required-user-attributes-directive.ts';
import 'ajs/modules/course/registrations/components/modal/registration-approval-status-component.ts';
import 'ajs/modules/course/registrations/components/modal/manage-custom-fields-modal-component.ts';
import 'ajs/modules/course/registrations/components/modal/change-target-completion-date-modal-component.ts';
import 'ajs/modules/course/registrations/components/modal/workflow-modal-component.ts';
import 'ajs/modules/course/registrations/components/dialogs/select-credit-types-component.ts';
import 'ajs/modules/course/registrations/components/dialogs/execution-confirmation-component.ts';
import 'ajs/modules/course/registrations/components/dialogs/execution-summary-component.ts';
import 'ajs/modules/course/registrations/components/dialogs/select-parent-course-credit-types-component.ts';
import 'ajs/modules/course/registrations/components/dialogs/select-custom-fields-component.ts';
import 'ajs/modules/course/registrations/components/dialogs/enter-registration-code-component.ts';
import 'ajs/modules/course/registrations/components/dialogs/enter-completion-code-component.ts';
import 'ajs/modules/course/registrations/components/dialogs/select-attendance-type-component.ts';
import 'ajs/modules/course/registrations/components/registration-credit-component.ts';
import 'ajs/modules/course/registrations/components/registration-credit-base-component.ts';
import 'ajs/modules/course/registrations/components/custom-info/edit/custom-info-edit-form-directive.ts';
import 'ajs/modules/course/registrations/components/custom-info/view/custom-info-directive.ts';
import 'ajs/modules/course/registrations/calendar/event-export-service.ts';
import 'ajs/modules/course/registrations/calendar/calendar-service.ts';
import 'ajs/modules/course/registrations/calendar/modal/add-to-calendar-component.ts';
import 'ajs/modules/groups/common/group-service.ts';
import 'ajs/modules/groups/validators/corporate-code.validator.ts';
import 'ajs/modules/search/_init.ts';
import 'ajs/modules/search/components/object-tags-directive.ts';
// import 'ajs/modules/search/search-component.ts';
import 'ajs/modules/search/common/_init.ts';
import 'ajs/modules/search/components/collapsable-search-bar-directive.ts';
import 'ajs/modules/search/components/catalog-search-bar-component.ts';
import 'ajs/modules/search/common/search-service.ts';
import 'ajs/modules/search/common/filtering/search-filtering-service.ts';
import 'ajs/modules/search/common/filtering/search-filter-base-service.ts';
import 'ajs/modules/search/common/filtering/custom-filters/course-label-filter-service.ts';
import 'ajs/modules/search/common/filtering/custom-filters/cp-organization-filter-service.ts';
import 'ajs/modules/search/common/filtering/custom-filters/certificate-filter-service.ts';
import 'ajs/modules/search/common/filtering/custom-filters/object-filter-service.ts';
import 'ajs/modules/search/common/filtering/custom-filters/quality-filter-service.ts';
import 'ajs/modules/search/common/filtering/custom-filters/user-search-filters-service.ts';
import 'ajs/modules/search/common/filtering/custom-filters/certification-types-filter-service.ts';
import 'ajs/modules/subject-areas/_init.ts';
import 'ajs/modules/subject-areas/subject-areas-directive.ts';
import 'ajs/modules/app/current-user-factory.ts';
import 'ajs/modules/user/certificates/_init.ts';
import 'ajs/modules/user/certificates/print/certificate-list-modal-component.ts';
import 'ajs/modules/user/certificates/certificates-component.ts';
import 'ajs/modules/user/certificates/certificate-tiles-component.ts';
import 'ajs/modules/user/certificates/user-certificates-service.ts';
import 'ajs/modules/user/certificates/modal/certificate-replacement-dialog-component.ts';
import 'ajs/modules/user/certificates/external/modal/delete-external-certificate-component.ts';
import 'ajs/modules/user/certificates/external/edit-external-certificate-component.ts';
import 'ajs/modules/user/certificates/external/components/certificate-file-directive.ts';
import 'ajs/modules/user/certificates/external/resource.ts';
import 'ajs/modules/user/certificates/external/components/certificate-file-upload-component.ts';
import 'ajs/modules/user/certificates/external/components/certificate-upload-directive.ts';
import 'ajs/modules/user/common/_init.ts';
import 'ajs/modules/user/common/user-groups-service.ts';
import 'ajs/modules/user/common/user-group-resource.ts';
import 'ajs/modules/user/common/user-time-zones-service.ts';
import 'ajs/modules/user/common/gender-service.ts';
import 'ajs/modules/user/common/geo-service.ts';
import 'ajs/modules/user/common/user-attributes-service.ts';
import 'ajs/modules/user/common/valid-registration-code-directive.ts';
import 'ajs/modules/user/common/dictionary-service.ts';
import 'ajs/modules/user/common/profile-settings.ts';
import 'ajs/modules/user/common/requirements-service.ts';
import 'ajs/modules/user/common/account-created-confirmation-component.ts';
import 'ajs/modules/user/common/roles-service.ts';
import 'ajs/modules/user/components/registration-code-input-component.ts';
import 'ajs/modules/user/components/user-firstname-input-component.ts';
import 'ajs/modules/user/components/user-lastname-input-component.ts';
import 'ajs/modules/user/components/user-policies-signoff-component.ts';
import 'ajs/modules/user/components/user-password-confirmation-input-component.ts';
import 'ajs/modules/user/components/username-input-component.ts';
import 'ajs/modules/user/components/user-zip-code-input-component.ts';
import 'ajs/modules/user/components/user-state-selection-component.ts';
import 'ajs/modules/user/components/user-timezone-input-component.ts';
import 'ajs/modules/user/components/user-phone-input-component.ts';
import 'ajs/modules/user/components/user-organization-input-component.ts';
import 'ajs/modules/user/components/user-email-input-component.ts';
import 'ajs/modules/user/components/user-address-input-component.ts';
import 'ajs/modules/user/components/user-address2-input-component.ts';
import 'ajs/modules/user/components/user-city-input-component.ts';
import 'ajs/modules/user/components/user-country-selection-component.ts';
import 'ajs/modules/user/upcoming-events/_init.ts';
import 'ajs/modules/user/upcoming-events/tile/directive.ts';
import 'ajs/modules/user/achievements/_init.ts';
import 'ajs/modules/user/achievements/common/user-achievements-resource.ts';
import 'ajs/modules/course/registrations/common/user-course-resource.ts';
import 'ajs/modules/course/registrations/common/user-courses-service.ts';
import 'ajs/modules/course/registrations/external/resource.ts';
import 'ajs/modules/course/registrations/components/modal/history-modal-component.ts';
import 'ajs/modules/user/learning/_init.ts';
import 'ajs/modules/user/learning/external/edit/modal/delete-external-course-component.ts';
import 'ajs/modules/user/learning/external/edit/edit-external-course-component.ts';
import 'ajs/modules/user/learning/dashboard/dashboard-component.ts';
import 'ajs/modules/user/learning/dashboard/dashboard-tile-component.ts';
import 'ajs/modules/user/learning/dashboard/dashboard-card-component.ts';
import 'ajs/modules/user/learning/dashboard/dashboard-card-item-component.ts';
import 'ajs/modules/user/learning/dashboard/dashboard-service.ts';
import 'ajs/modules/user/learning/dashboard/mini-dashboard-component.ts';
import 'ajs/modules/user/learning/recent_courses/modern-recent-courses-directive.ts';
import 'ajs/modules/user/learning-series/_init.ts';
import 'ajs/modules/user/learning-series/common/user-learning-series-service.ts';
import 'ajs/modules/user/learning-series/resource.ts';
import 'ajs/modules/user/learning-series/components/ls-user-status-component.ts';
import 'ajs/modules/user/learning-series/components/legacy-ls-tree-structure-component.ts';
import 'ajs/modules/user/learning-series/components/user-learning-series-structure-component.ts';
import 'ajs/modules/user/mail/_init.ts';
import 'ajs/modules/user/mail/opt_in/opt-in-service.ts';
import 'ajs/modules/user/mail/opt_in/unsubscribe-service.ts';
import 'ajs/modules/user/mail/opt_in/email-confirmed-component.ts';
import 'ajs/modules/user/mail/opt_in/unsubscribe-component.ts';
import 'ajs/modules/user/mail/opt_in/modal/opt-in-component.ts';
import 'ajs/modules/user/edit/components/_init.ts';
import 'ajs/modules/user/edit/components/account-info-directive.ts';
import 'ajs/modules/user/edit/components/contact-info-directive.ts';
import 'ajs/modules/user/edit/components/edit-user-control-directive.ts';
import 'ajs/modules/user/edit/components/email-optin-management-component.ts';
import 'ajs/modules/user/edit/components/modal/change-password-modal-component.ts';
import 'ajs/modules/user/edit/components/modal/provide-password-modal-component.ts';
import 'ajs/modules/user/edit/components/modal/reconfirm-email-required-dialog-component.ts';
import 'ajs/modules/user/edit/components/modal/reset-password-modal-component.ts';
import 'ajs/modules/user/edit/components/modal/save-profile-component.ts';
import 'ajs/modules/user/edit/components/network-info-directive.ts';
import 'ajs/modules/user/edit/components/user-avatar-new-directive.ts';
import 'ajs/modules/user/edit/reset-password/component.ts';
import 'ajs/modules/user/edit/policies-sign-off/policies-sign-off-component.ts';
import 'ajs/modules/user/_init.ts';
import 'ajs/modules/user/view/components/user-avatar-component.ts';
import 'ajs/modules/user/view/components/user-profile-menu-component.ts';
import 'ajs/modules/user/edit/_init.ts';
import 'ajs/modules/user/edit/edit-account-component.ts';
import 'ajs/modules/user/registration/_init.ts';
import 'ajs/modules/user/registration/common/user-registration-factory.ts';
import 'ajs/modules/user/registration/user-registration-component.ts';
import 'ajs/modules/user/registration/components/user-info-registration-component.ts';
import 'ajs/modules/user/registration/components/user-attributes-registration-component.ts';
import 'ajs/modules/user/registration/components/simple-registration-component.ts';
import 'ajs/modules/user/registration/components/self-registration-groups-selection-step-component.ts';
import 'ajs/modules/user/edit/components/self-registration-groups-selection-component.ts';
import 'ajs/modules/user/notifications/_init.ts';
import 'ajs/modules/user/notifications/common/notifications-service.ts';
import 'ajs/modules/user/network/_init.ts';
import 'ajs/modules/quiz/_init.ts';
import 'ajs/modules/quiz/common/quiz.ts';
import 'ajs/modules/quiz/common/quiz-options-service.ts';
import 'ajs/modules/quiz/common/quiz-question.ts';
import 'ajs/modules/quiz/common/enums-const.ts';
import 'ajs/modules/quiz/common/quiz-result.ts';
import 'ajs/modules/quiz/common/temporary-files-service.ts';
import 'ajs/modules/quiz/results/result-view-directive.ts';
import 'ajs/modules/network/_init.ts';
import 'ajs/modules/network/common/activity.ts';
import 'ajs/modules/network/common/comment.ts';
import 'ajs/modules/network/common/followers.ts';
import 'ajs/modules/network/network-service.ts';
import 'ajs/modules/network/network-rotator-service.ts';
import 'ajs/modules/network/network-rotator-component.ts';
import 'ajs/modules/network/network-search-service.ts';
import 'ajs/modules/network/network-followers-service.ts';
import 'ajs/modules/network/summary/community-summary-service.ts';
import 'ajs/modules/network/summary/community-details-component.ts';
import 'ajs/modules/passwords/_init.ts';
import 'ajs/modules/passwords/components/password-input-component.ts';
import 'ajs/modules/passwords/components/password-validator.ts';
import 'ajs/modules/passwords/components/password-policies-component.ts';
import 'ajs/modules/passwords/password-policies-service.ts';
