import { FactoryProvider } from '@angular/core';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';

export function currentUserServiceFactory(injector: angular.auto.IInjectorService): CurrentUserService {
  return injector.get<CurrentUserService>('currentUser');
}

export const currentUserServiceProvider: FactoryProvider = {
  provide: CurrentUserService,
  useFactory: currentUserServiceFactory,
  deps: ['$injector'],
};
