import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationService } from 'ajs/modules/app/environment/notification-service';
import { JSONData } from 'core/models';
import { ERROR_NOTIFICATION_TIMEOUT, ErrorsService } from 'core/services/errors.service';
import { EMPTY, Observable, catchError, throwError } from 'rxjs';

/**
 * Boolean HttpContextToken that skips all errors.
 * @default false
 * @example
 * ```
 * httpClient
 *  .get('/data/feed', {
 *    context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, true),
 *  })
 *  .subscribe(results => { ... })
 * ```
 * @description This token is used only when a component needs to display an error message on the page instead of
 * central notification.
 * @see See also `PASS_HTTP_ERROR` context.
 */
export const PASS_HTTP_ERRORS_TOKEN = new HttpContextToken<boolean>(() => false);

@Injectable()
export class HttpRequestErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private notificationService: NotificationService,
    private errorsService: ErrorsService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const passHttpErrors = request.context.get(PASS_HTTP_ERRORS_TOKEN);

    return next.handle(request).pipe(catchError((e: HttpErrorResponse) => this.handleError(e, passHttpErrors)));
  }

  private handleError(response: HttpErrorResponse, passHttpErrors: boolean) {
    if (!passHttpErrors) {
      if (response.status === 404 && response.headers.getAll('content-type')?.join('').includes('text/html')) {
        return EMPTY;
      } else if (response.status === 0 || response.status >= 400) {
        const error = this.errorsService.getErrorDetails(response);
        const message = typeof error === 'string' ? error.toString() : ErrorsService.defaultMessage;

        this.notificationService.error(message, ERROR_NOTIFICATION_TIMEOUT);

        return EMPTY;
      }
    }

    return throwError(() => this.cloneError(response, this.errorsService.getErrorDetails(response)));
  }

  private cloneError(error: HttpErrorResponse, message: string | JSONData): HttpErrorResponse {
    return new HttpErrorResponse({
      error: message,
      headers: error.headers,
      status: error.status,
      statusText: error.statusText,
      url: error.url,
    });
  }
}
