import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { QuizzesSearchComponent } from 'modules/admin/quiz/componenets/quizzes-search.component';
import { EditQuizQuestionsComponent } from 'modules/admin/quiz/question/components/edit-quiz-questions.component';
import { QuizQuestionsManagerService } from 'modules/admin/quiz/question/services/quiz-questions-manager.service';
import { QuizQuestionsVersionManagerService } from 'modules/admin/quiz/question/services/quiz-quiestion-version-manager.service';
import { QuizPlayerQuestionComponent } from 'modules/quiz/player/quiz-player-question.component';
import { QuizPlayerComponent } from 'modules/quiz/player/quiz-player.component';
import { lastValueFrom } from 'rxjs';

declare const angular: angular.IAngularStatic;

export default angular
  .module('kmi.lms.core')
  .directive(QuizPlayerQuestionComponent.selector, downgradeComponent({ component: QuizPlayerQuestionComponent }))
  .directive(QuizzesSearchComponent.selector, downgradeComponent({ component: QuizzesSearchComponent }))
  .directive(EditQuizQuestionsComponent.selector, downgradeComponent({ component: EditQuizQuestionsComponent }))
  .factory('quizQuestionManagerUpgradedService', downgradeInjectable(QuizQuestionsManagerService))
  .factory('QuizQuestionsVersionManagerUpgraded', downgradeInjectable(QuizQuestionsVersionManagerService))
  .factory('quizQuestionsVersionManager', ($injector) => {
    const service = $injector.get('QuizQuestionsVersionManagerUpgraded') as QuizQuestionsVersionManagerService;

    return {
      getQuestionVersionChain: (quiestionId) => {
        return lastValueFrom(service.getQuestionVersionChain(quiestionId));
      },
      showNewVersionPrompt: (question) => {
        return service.showNewVersionPrompt(question);
      },
    };
  })
  .directive(QuizPlayerComponent.selector, downgradeComponent({ component: QuizPlayerComponent }));
