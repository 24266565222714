import { FactoryProvider } from '@angular/core';
import { ISearchFilterOptionsService, ISearchFilterOptionsUpgraded } from '../../models/search-filters.models';

export abstract class ApprovalFilterService {
  abstract getApprovalFilterConfig(options?: Partial<ISearchFilterOptionsUpgraded>): ISearchFilterOptionsService;
}

export function searchApprovalFilterFactory(injector: angular.auto.IInjectorService): ISearchFilterOptionsService {
  return injector.get<ISearchFilterOptionsService>('searchFilterApprovalStatus');
}

export const searchApprovalFilterProvider: FactoryProvider = {
  provide: ApprovalFilterService,
  useFactory: searchApprovalFilterFactory,
  deps: ['$injector'],
};
