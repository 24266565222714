import { FactoryProvider } from '@angular/core';

export abstract class UrlVersionService {
  get: () => number | null;
  update: () => void;
}

export function urlVersionServiceFactory(injector: angular.auto.IInjectorService): UrlVersionService {
  return injector.get<UrlVersionService>('urlVersionService');
}

export const urlVersionServiceProvider: FactoryProvider = {
  provide: UrlVersionService,
  useFactory: urlVersionServiceFactory,
  deps: ['$injector'],
};
