import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { IUserNetworkActivity } from 'modules/network/models/user-network-activity.model';
import { NetworkService } from 'modules/network/services/network.service';
import { UserNetworkActivityService } from 'modules/network/services/user-network-activity.service';
import { NetworkActivityComponent } from 'modules/network/view/network-activity.component';
import { lastValueFrom, map } from 'rxjs';

declare const angular: angular.IAngularStatic;

export default angular
  .module('kmi.lms.core')
  .directive('networkActivity', downgradeComponent({ component: NetworkActivityComponent }))
  .factory('UserNetworkActivityService', downgradeInjectable(UserNetworkActivityService))
  .factory('networkServiceUpgraded', ($injector) => {
    const networkActivity = $injector.get('UserNetworkActivity');
    const service = $injector.get('NetworkService');

    service.init(networkActivity);

    return {
      showEditThreadDialog: (params) => {
        return service.showEditThreadDialog(params);
      },
    };
  })
  .factory('UserNetworkActivity', ($injector) => {
    const service = $injector.get('UserNetworkActivityService');

    return {
      newPost: (atr) => {
        return service.newPost(atr);
      },
      query: (params, callback) => {
        return {
          $promise: lastValueFrom(service.query(params).pipe(map(callback))),
        };
      },
      save: (params) => {
        return {
          $promise: lastValueFrom(service.save(params)),
        };
      },
    };
  })
  .factory('NetworkService', downgradeInjectable(NetworkService<IUserNetworkActivity>));
