import { FactoryProvider } from '@angular/core';

export interface IMeta {
  title?: string;
  description?: string;
  url?: string;
  image?: string;
  type?: string;
}

export abstract class MetaService {
  title: (value: string) => string;
  description: (value: string) => string;
  url: (value: string) => string;
  image: (value: string) => string;
  type: (value: string) => string;
  setMeta: (value: IMeta) => void;
  clearMeta: () => void;
}

export function metaServiceFactory(injector: angular.auto.IInjectorService): MetaService {
  return injector.get<MetaService>('metaService');
}

export const metaServiceProvider: FactoryProvider = {
  provide: MetaService,
  useFactory: metaServiceFactory,
  deps: ['$injector'],
};
