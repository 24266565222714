<div class="flex">
  <div class="flex-row sm-margin-bottom">
    <div class="flex-col flex-col-grow text-left">
      <a
        href=""
        class="btn btn-link no-padding-left modern-back-button"
        title="Go back"
        (click)="$event.stopPropagation(); $event.preventDefault(); goBack()"
        ><span class="fa fa-chevron-left" aria-hidden="true"></span>Back</a
      >
    </div>
  </div>
</div>
