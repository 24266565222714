import { Injectable } from '@angular/core';
import { GlobalConfig } from 'core/environment';
import { ISearchFilterTerm } from 'modules/search/models/search-filters.models';
import { ISearchFilterTypes, ISearchFilterUpgraded } from '../../models/search-filters.models';
import { BaseFilterService } from './search-filtering.service.ajs-upgraded';

@Injectable()
export class SearchFiltersProviderUpgradedService {
  constructor(
    private filterService: BaseFilterService,
    private globalConfig: GlobalConfig,
  ) {
    filterService.onInit(() => this.initFilters());
  }

  loadFilters(): ISearchFilterTypes {
    return this.filterService.loadFilters();
  }

  get adminSortTerms(): ISearchFilterTerm[] {
    return this.globalConfig.settings.search.sortItems;
  }

  get adminShowResultsTerms(): ISearchFilterTerm[] {
    return this.globalConfig.settings.search.terms;
  }

  private initFilters(): ISearchFilterTypes {
    const uiFilters: ISearchFilterUpgraded[] = [];
    const allFilters = new Map<string, ISearchFilterUpgraded>();
    // const items = this.globalConfig.settings.search.filters;

    // for (let i = 0; i < items?.length; i++) {
    //   const item = items[i];
    //   // const filter = this.filterMapper.get(item.name, item.config);

    //   // if (filter) {
    //   //   if (item.associateWith) {
    //   //     this.filterService.associateFilters(allFilters.get(item.associateWith), filter);
    //   //   }
    //   //
    //   //   if (item.subfilter) {
    //   //     filter.subfilter = this.filterMapper.get(item.subfilter);
    //   //
    //   //     if (item.associateWith) {
    //   //       this.filterService.associateFilters(allFilters.get(item.associateWith), filter.subfilter);
    //   //     }
    //   //   }
    //   //
    //   //   if (item.ui) {
    //   //     uiFilters.push(filter);
    //   //   }
    //   //
    //   //   allFilters.set(item.name, filter);
    //   // }
    // }

    return { ui: uiFilters, all: Array.from(allFilters.values()) };
  }
}
