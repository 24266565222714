import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CACHE_NAME_TOKEN, CACHE_TTL_TOKEN, TTL } from 'core/http-interceptors/http-caching-interceptor';
import { IAffiliate, ICountry, IState, ITimeZone, TCity } from 'core/services/geo/geo.models';
import _ from 'lodash';
import { Observable, map, of, tap } from 'rxjs';

interface ICountries {
  topCountries: ICountry[];
  countries: ICountry[];
}

@Injectable()
export class GeoService {
  readonly TIME_ZONES_CACHE = 'timeZones';
  readonly COUNTRIES_CACHE = 'countries';
  readonly CITIES_CACHE = 'cities';

  constructor(private http: HttpClient) {}

  public getTimeZones(): Observable<ITimeZone[]> {
    return this.http.get<ITimeZone[]>('/a/time_zone/all/', {
      context: new HttpContext().set(CACHE_NAME_TOKEN, this.TIME_ZONES_CACHE).set(CACHE_TTL_TOKEN, TTL.NEVER_EXPIRE),
    });
  }

  public getTimeZone(id: number): Observable<ITimeZone | null> {
    return this.getTimeZones().pipe(map((timeZones) => timeZones.find((timeZone) => timeZone.id === id) || null));
  }

  public getTimeZoneGmtOffsetString(timeZone: ITimeZone): string {
    if (timeZone.gmtOffsetHour) {
      return [
        timeZone.gmtOffsetHour,
        ':',
        parseInt(timeZone.gmtOffsetMinute, 0) ? timeZone.gmtOffsetMinute : '00',
      ].join('');
    }

    return timeZone.name;
  }

  public getStates(country: string): Observable<IState[]> {
    const localStorageKey = `states:${country}`;
    const cachedData = localStorage.getItem(localStorageKey);

    if (cachedData) {
      return of(JSON.parse(cachedData) as IState[]);
    }

    return this.http
      .get<IState[]>(['/a/user/geo/country/', country, '/states/'].join(''))
      .pipe(tap((states) => localStorage.setItem(localStorageKey, JSON.stringify(states))));
  }

  public getState(country: string, state: number): Observable<IState | null> {
    return this.getStates(country).pipe(map((states) => _.find(states, { id: state })));
  }

  public getStateByZip(zip: string, withCity: boolean): Observable<IState> {
    return this.http.get<IState>(['/a/user/geo/zip/', zip, '/'].join(''), {
      params: { city: withCity },
    });
  }

  public getAffiliatesByZip(zip: string): Observable<IAffiliate[]> {
    return this.http.get<IAffiliate[]>(['/a/about_elms/settings_by_zip/', zip, '/'].join(''));
  }

  public getCountries(showPinned: boolean): Observable<ICountry[]> {
    return this.http
      .get<ICountries>('/a/user/geo/countries/', {
        context: new HttpContext().set(CACHE_NAME_TOKEN, this.COUNTRIES_CACHE).set(CACHE_TTL_TOKEN, TTL.NEVER_EXPIRE),
      })
      .pipe(
        map((countriesList) => {
          if (showPinned) {
            const countries = countriesList.topCountries;

            return countries.concat(countriesList.countries);
          } else {
            return countriesList.countries;
          }
        }),
      );
  }

  public getCities(stateId: number): Observable<TCity> {
    return this.http.get<TCity>(['/a/user/geo/state/', stateId, '/cities/'].join(''), {
      context: new HttpContext().set(CACHE_NAME_TOKEN, this.CITIES_CACHE).set(CACHE_TTL_TOKEN, TTL.NEVER_EXPIRE),
    });
  }
}
