import { FactoryProvider } from '@angular/core';

export abstract class LocationHistoryService {
  clear: () => void;
  getLength: () => number;
  holdLastVisit: () => void;
  clearLastVisit: () => void;
  replaceParams: (stateName: any, oldParams: any, newParams: any) => void;
  pop: () => any;
  push: (value: any) => void;
  remove: (state: any, params: any) => void;
  getLastState: () => any;
}

export function locationHistoryServiceFactory(injector: angular.auto.IInjectorService): LocationHistoryService {
  return injector.get<LocationHistoryService>('locationHistory');
}

export const locationHistoryServiceProvider: FactoryProvider = {
  provide: LocationHistoryService,
  useFactory: locationHistoryServiceFactory,
  deps: ['$injector'],
};
