import { FactoryProvider } from '@angular/core';
import { ISearchAvailableFilter } from 'app/deprecated/search/models/search-filters.models';
import { ICalendarDataModes } from 'modules/calendar/models/events.model';
import { QuizQuestionType, QuizType } from 'modules/quiz';
import { ISearchFilterConfig, ISearchFilterTerm } from 'modules/search/models/search-filters.models';

export abstract class GlobalConfig {
  base: string;
  domain: string;
  externalDomain?: string;
  appType: string;
  projectName: string;
  environment: string;
  markdown: { sanitize: boolean };
  settings: {
    aiToolsEnabled?: boolean;
    assignUsersRoles?: number[];
    authorizedStates?: any[];
    view: string;
    ecommerceEnabled?: boolean;
    organizationsEnabled?: boolean;
    network?: {
      tabName: string;
    };
    registration?: {
      steps: string[];
    };
    trainingPlansEnabled?: boolean;
    anonymizeMenuShow?: boolean;
    warnings?: {
      multipleGroupSelection?: boolean;
    };
    blogPosts?: {
      postNotificationEnabled?: boolean;
      heroImageDetails?: boolean;
    };
    organization?: {
      emailRequired?: boolean;
      groupCategories?: number[];
    };
    creditTypes?: {
      availabilityEnabled?: boolean;
      accreditationsEnabled?: boolean;
    };
    editCreditTypeAdminRoles?: number[];
    achievementsConfig?: {
      enabled: boolean;
      leaderboardEnabled: boolean;
    };
    course?: {
      edit?: {
        reviewsSettingsEnabled: boolean;
      };
    };
    courseRecommendations?: boolean;
    courseAssignments?: boolean;
    courseSelection?: {
      allowAddById: boolean;
    };
    courseDetails: {
      reviewsVisible?: boolean;
      skillLevelCourseFormatMeta?: boolean;
      examCustomQuizPlayerSteps?: string[];
      forceNewWindowFormats?: number[];
      adminDetailsFormats?: number[];
    };
    courseDetailsTile: {
      publishedByOrganization: boolean;
    };
    courseThumbnailSettings?: {
      randomBackgroundColor: boolean;
      skipDefault: boolean;
    };
    reports?: {
      dashboardReportFiltersEnabled?: boolean;
      filters: {
        organizations: {
          skipDefault: boolean;
        };
      };
    };
    quizEdit?: {
      questionBank?: {
        enabled: boolean;
        cloneQuestions?: boolean;
        quizTypes: QuizType[];
        allowExclusiveQuizQuestions: boolean;
      };
      questions?: {
        simpleSelect?: boolean;
      };
      timeTrackingAttemptsEnabled: boolean;
      showTitle: boolean;
      availableQuestionTypeExtensions: {
        [key in QuizType]: { id: number; typeId: number }[];
      };
      typeExtensionTemplates: {
        [key in QuizType]: { extensionId: number; answerVariants: { text: string; correct: boolean }[] }[];
      };
      availableQuestionTypes: {
        [key in QuizType]: QuizQuestionType[];
      };
    };
    mandatoryLabel?: string | null;
    fullCalendar: {
      availableFor: string[];
      template: string;
      filterProvider: string;
      showRegistrationStatus: boolean;
      mode: ICalendarDataModes;
    };
    courseCatalog: {
      availableFor: string[];
    };
    searchFilters: {
      catalog: ISearchAvailableFilter[];
    };
    communityEnabled?: boolean;
    enrolledLearnersEnabled?: boolean;
    quizOptions?: {
      timeTrackingAttemptsEnabled: boolean;
      showTitle: boolean;
    };
    coursePlayerShowCloseButton?: boolean;
    coursePlayer?: {
      collectionPlaylist: {
        playbackButtons: boolean;
        numbers: boolean;
      };
      showSteps?: boolean;
      hideNextStepBtnFor: number[];
      completionPopupShowOnce: boolean;
      relatedCollections: boolean;
      upNext: boolean;
    };
    user?: {
      expirationDateEnabled: boolean;
      emailRequired: boolean;
      adminSinglePageDisabled: boolean;
      checkDuplicatesForActiveOnly: boolean;
    };
    groups?: {
      commonGroupCategoryId: number;
      search: {
        selectAllMatched: boolean;
        exactMatch: boolean;
      };
    };
    adminSearch: {
      userExpandedView: {
        showLoginNumber: boolean;
        showExpired: boolean;
      };
    };
    search: {
      filters: ISearchFilterConfig[];
      terms: ISearchFilterTerm[];
      sortItems: ISearchFilterTerm[];
    };
    login: {
      forwardAdminToAdminApp: boolean;
      showPolicies: boolean;
      corporateLoginInvitation: string;
      disclaimer: string;
      becomeCourseProvider: boolean;
      createAccountStyle: string;
    };
    availabilityTreeView?: boolean;
    deepLinkingExcludedStates: string[];
    support: {
      email: string;
    };
  };

  courseRegistration: {
    excludedStatuses?: number[];
    verificationEnabled?: boolean;
  };

  signInWording: { [name: string]: string };
  genericCourseRosterLabels: { [name: string]: string };
  adminContentSections: { organizations: { fields: string[] } };
  adminUserDetails?: {
    state?: string;
    enableEditUserAttributes?: boolean;
  };

  scorm?: {
    WebserviceLogLevel?: number;
    ConsoleLogLevel?: number;
    TimeTrackingEnabled?: boolean;
    HttpCallMaxAttempts?: number;
  };

  manageUsers?: {
    title: string;
  };

  mainMenu: {
    maxVisibleItems: number;
    extenderTitle: string;
  };

  menu: any[];
  adminMenuItems: any[];
  courseApprovalCustomStatuses: Record<number, string>;
}

export function globalConfigFactory(): GlobalConfig {
  return window['_globalConfig'] as GlobalConfig;
}

export const globalConfigProvider: FactoryProvider = {
  provide: GlobalConfig,
  useFactory: globalConfigFactory,
};

// TODO Review
export const GLOBAL_CONFIG = window['_globalConfig'] as GlobalConfig;
