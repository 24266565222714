import * as _ from 'lodash';
import { EnvironmentSettings } from '../../src/lms-environment/environment-manager';

export class GoogleAnalytics {
  private readonly tagManagerTrackingId: string;
  private readonly googleAnalyticsCode: string;
  private readonly googleAnalyticsAlternative: string;

  constructor(private environmentSettings: EnvironmentSettings) {
    this.tagManagerTrackingId = _.get(this.environmentSettings.elmsEnvironment, 'siteSettings.googleTagManagerCode');
    this.googleAnalyticsCode = _.get(
      this.environmentSettings.elmsEnvironment,
      'siteSettings.googleAnalyticsCode',
      null,
    );

    this.googleAnalyticsAlternative = _.get(
      this.environmentSettings.elmsEnvironment,
      'googleAnalyticsCodeAlternative',
      null,
    );

    this.googleAnalyticsAlternative =
      !this.environmentSettings.elmsEnvironment.siteSettings.default &&
      this.googleAnalyticsCode !== this.googleAnalyticsAlternative
        ? this.googleAnalyticsAlternative
        : null;
  }

  setup() {
    if (this.tagManagerTrackingId) {
      this._setupTagManager();
    } else {
      this._setupGtag();
    }
  }

  private _setupGtag(): void {
    (function (gaId) {
      const gtagScript = document.createElement('script');
      gtagScript.type = 'text/javascript';
      gtagScript.async = true;
      gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + gaId;

      const el = document.getElementsByTagName('script')[0];
      el.parentNode.insertBefore(gtagScript, el);
    })(this.googleAnalyticsCode || this.googleAnalyticsAlternative);

    (<any>window).dataLayer = (<any>window).dataLayer || [];
    (<any>window).gtag = function () {
      (<any>window).dataLayer.push(arguments);
    };

    (<any>window).gtag('js', new Date());

    if (this.googleAnalyticsCode) {
      (<any>window).gtag('config', this.googleAnalyticsCode, {
        send_page_view: false,
        groups: 'default',
      });
    }

    if (this.googleAnalyticsAlternative) {
      (<any>window).gtag('config', this.googleAnalyticsAlternative, {
        send_page_view: false,
        groups: 'affiliateTracker',
      });
    }
  }

  private _setupTagManager(): void {
    (function (w, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
      const f = document.getElementsByTagName('script')[0],
        j = document.createElement('script'),
        dl = l != 'dataLayer' ? '&l=' + l : ''; // eslint-disable-line eqeqeq
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, 'dataLayer', this.tagManagerTrackingId);

    document.body.innerHTML +=
      '<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=' +
      this.tagManagerTrackingId +
      '" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>';
  }
}
