import { FactoryProvider } from '@angular/core';
import { ISearchFilterOptionsService, ISearchFilterOptionsUpgraded } from '../../models/search-filters.models';

export abstract class CourseFilterService {
  abstract getOrganizationFilterConfig(options?: Partial<ISearchFilterOptionsUpgraded>): ISearchFilterOptionsService;
  abstract getOrganizationUserFilter(): ISearchFilterOptionsService;
  abstract getCourseCreatorFilter(options?: Partial<ISearchFilterOptionsUpgraded>): ISearchFilterOptionsService;
  abstract getNationalCpFilter(options?: Partial<ISearchFilterOptionsUpgraded>): ISearchFilterOptionsService;
  abstract getMyOrganizationFilter(options?: Partial<ISearchFilterOptionsUpgraded>): ISearchFilterOptionsService;
}

export function searchCourseFilterFactory(injector: angular.auto.IInjectorService): ISearchFilterOptionsService {
  return injector.get<ISearchFilterOptionsService>('searchFilterCourseProviders');
}

export const searchCourseFilterProvider: FactoryProvider = {
  provide: CourseFilterService,
  useFactory: searchCourseFilterFactory,
  deps: ['$injector'],
};
