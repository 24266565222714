import { downgradeInjectable } from '@angular/upgrade/static';
import { BlogPostService } from 'modules/blog/services/blog-post.service';
import { lastValueFrom, map } from 'rxjs';

declare const angular: angular.IAngularStatic;

function moduleRun(entityDataService, $injector) {
  entityDataService.registerLoader('blog', function (id: string) {
    const blogPostService = $injector.get('BlogPost') as BlogPostService;

    return lastValueFrom(blogPostService.get(Number(id)).pipe(map((blog) => Object.assign({ $resolved: true }, blog))));
  });
}

export default angular.module('elmsSpaApp').factory('BlogPost', downgradeInjectable(BlogPostService)).run(moduleRun);
