(function () {
  angular.module('kmi.lms.services', []).service('rootScopeService', rootScopeService);

  /* @ngInject */
  function rootScopeService($rootScope) {
    var vm = this;

    vm.scope = {};
    vm.rootScope = $rootScope; //temporality
    vm.on = on;
    vm.broadcast = broadcast;
    vm.evalAsync = evalAsync;
    vm.apply = apply;

    function on(key, listener) {
      return $rootScope.$on(key, listener);
    }

    function broadcast() {
      return $rootScope.$broadcast.apply($rootScope, arguments);
    }

    function evalAsync(expression, locals) {
      return $rootScope.$evalAsync(expression, locals);
    }

    function apply(expression) {
      return $rootScope.$apply(expression);
    }
  }
})();
