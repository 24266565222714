import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbAccordionModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { CompetencyModule } from 'modules/competency/competency.module';
import { CourseTilesModule } from 'modules/course/views/tiles/course-tiles.module';
import { BooleanFilterDepComponent } from './components/filtering/boolean-filter.component';
import { BooleanSubFilterDepComponent } from './components/filtering/boolean-sub-filter.component';
import { CompositeFilterDepComponent } from './components/filtering/composite-filter.component';
import { DatesChoiceFilterDepComponent } from './components/filtering/dates-choice-filter.component';
import { FullScreenFilterViewDepComponent } from './components/filtering/full-screen-filter-view.component';
import { CompetencyFilterDepComponent } from './components/filtering/modal/competency-filter.component';
import { CourseAttributeFilterDepComponent } from './components/filtering/modal/course-attribute-filter.component';
import { GroupTagsFilterDepComponent } from './components/filtering/modal/group-tags-filter.component';
import { SearchableMultiChoiceDepComponent } from './components/filtering/modal/searchable-multi-choice.component';
import { SessionLabelFilterDepComponent } from './components/filtering/modal/session-label-filter.component';
import { MultiChoiceFilterDepComponent } from './components/filtering/multi-choice-filter.component';
import { NumberChoiceFilterDepComponent } from './components/filtering/number-choice-filter.component';
import { PopupMultiChoiceDepComponent } from './components/filtering/popup-multi-choice.component';
import { PriceFilterDepComponent } from './components/filtering/price/price-filter.component';
import { PriceFormDepComponent } from './components/filtering/price/price-form.component';
import { PromptingFilterDepComponent } from './components/filtering/prompting-filter.component';
import { SearchFilterViewDepComponent } from './components/filtering/search-filter-view.component';
import { SearchableMultiChoiceFilterDepComponent } from './components/filtering/searchable-multi-choice-filter.component';
import { SelectedFilterRatingDepComponent } from './components/filtering/selected-filter-rating.component';
import { SelectedSearchFiltersDepComponent } from './components/filtering/selected-search-filters.component';
import { SingleChoiceFilterDepComponent } from './components/filtering/single-choice-filter.component';
import { SearchMobileActionPanelDepComponent } from './components/search-mobile-action-panel.component';
import { SearchResultRowDepComponent } from './components/search-result-row.component';
import { SearchResultStatsDepComponent } from './components/search-result-stats.component';
import { SearchResultDepComponent } from './components/search-result.component';
import { searchApprovalFilterProvider } from './services/filters-old/approval-filter.service.ajs-upgraded';
import { searchCertificateFilterProvider } from './services/filters-old/certificate-filter.service.ajs-upgraded';
import { collectionTypesFilterProvider } from './services/filters-old/collection-types-filtering.service.ajs-upgraded';
import { searchCourseFilterProvider } from './services/filters-old/course-filter.service.ajs-upgraded';
import { searchLabelFilterProvider } from './services/filters-old/label-filter.service.ajs-upgraded';
import { searchLSFilterProvider } from './services/filters-old/ls-filter.service.ajs-upgraded';
import { searchObjectFilterProvider } from './services/filters-old/object-filter.service.ajs-upgraded';
import { searchFiltersProvider } from './services/filters-old/search-filtering.service.ajs-upgraded';
import { SearchFiltersMapperUpgradedService } from './services/filters-old/search-filters-mapper.service';
import { SearchFiltersProviderUpgradedService } from './services/filters-old/search-filters-provider.service';
import { searchFiltersTrainProvider } from './services/filters-old/train-search-filtering.service.ajs-upgraded';
import { searchUserFilterProvider } from './services/filters-old/user-filter.service.ajs-upgraded';
import { searchServiceProvider } from './services/search.service.ajs-upgraded-provider';
import { CourseCommonModule } from 'modules/course/common/course-common.module';

@NgModule({
  imports: [
    UIRouterModule,
    CommonModule,
    FormsModule,
    NgbAccordionModule,
    NgbDropdownModule,
    LmsComponentsModule,
    CourseTilesModule,
    CompetencyModule,
    CdkTreeModule,
    CourseCommonModule,
  ],
  declarations: [
    CompetencyFilterDepComponent,
    CourseAttributeFilterDepComponent,
    GroupTagsFilterDepComponent,
    SearchableMultiChoiceDepComponent,
    SessionLabelFilterDepComponent,
    PriceFilterDepComponent,
    PriceFormDepComponent,
    BooleanFilterDepComponent,
    BooleanSubFilterDepComponent,
    CompositeFilterDepComponent,
    DatesChoiceFilterDepComponent,
    FullScreenFilterViewDepComponent,
    MultiChoiceFilterDepComponent,
    NumberChoiceFilterDepComponent,
    PopupMultiChoiceDepComponent,
    PromptingFilterDepComponent,
    SearchFilterViewDepComponent,
    SearchableMultiChoiceFilterDepComponent,
    SelectedFilterRatingDepComponent,
    SelectedSearchFiltersDepComponent,
    SingleChoiceFilterDepComponent,
    SearchMobileActionPanelDepComponent,
    SearchResultRowDepComponent,
    SearchResultStatsDepComponent,
    SearchResultDepComponent,
  ],
  providers: [
    searchFiltersProvider,
    searchFiltersTrainProvider,
    searchServiceProvider,

    searchObjectFilterProvider,
    searchLabelFilterProvider,
    searchUserFilterProvider,
    searchCertificateFilterProvider,
    searchCourseFilterProvider,
    searchApprovalFilterProvider,
    searchLSFilterProvider,
    collectionTypesFilterProvider,

    SearchFiltersMapperUpgradedService,
    SearchFiltersProviderUpgradedService,
  ],
  exports: [
    CompetencyFilterDepComponent,
    CourseAttributeFilterDepComponent,
    GroupTagsFilterDepComponent,
    SearchableMultiChoiceDepComponent,
    SessionLabelFilterDepComponent,
    PriceFilterDepComponent,
    PriceFormDepComponent,
    BooleanFilterDepComponent,
    BooleanSubFilterDepComponent,
    CompositeFilterDepComponent,
    DatesChoiceFilterDepComponent,
    FullScreenFilterViewDepComponent,
    MultiChoiceFilterDepComponent,
    NumberChoiceFilterDepComponent,
    PopupMultiChoiceDepComponent,
    PromptingFilterDepComponent,
    SearchFilterViewDepComponent,
    SearchableMultiChoiceFilterDepComponent,
    SelectedFilterRatingDepComponent,
    SelectedSearchFiltersDepComponent,
    SingleChoiceFilterDepComponent,
    SearchMobileActionPanelDepComponent,
    SearchResultRowDepComponent,
    SearchResultStatsDepComponent,
    SearchResultDepComponent,
  ],
})
export class SearchDeprecatedModule {}
