import { downgradeInjectable } from '@angular/upgrade/static';
import { DiscussionsService } from 'modules/discussions/services/discussions.service';
import { lastValueFrom, map } from 'rxjs';

declare const angular: angular.IAngularStatic;

function moduleRun(entityDataService, $injector) {
  entityDataService.registerLoader('discussion', function (id: string) {
    const discussionsService = $injector.get('DiscussionTopic') as DiscussionsService;

    return lastValueFrom(
      discussionsService.get(Number(id)).pipe(map((resource) => Object.assign({ $resolved: true }, resource))),
    );
  });
}

export default angular
  .module('kmi.lms.core')
  .factory('DiscussionTopic', downgradeInjectable(DiscussionsService))
  .run(moduleRun);
