import { downgradeComponent } from '@angular/upgrade/static';
import { LoginFormComponent } from 'modules/login/components/login-form.component';

declare const angular: angular.IAngularStatic;

export default angular
  .module('kmi.lms.core')
  .directive(
    LoginFormComponent.selector,
    downgradeComponent({ component: LoginFormComponent }) as angular.IDirectiveFactory,
  );
