import { FactoryProvider } from '@angular/core';
import { Observable, from } from 'rxjs';

abstract class EntityDataServiceUpgradedProvider {
  registerLoader: (entity: string, callBack: (id: number | string) => any) => void;
  getData: (key: string, id: number | string) => Promise<any>;
}

export class EntityDataService {
  constructor(private provider: EntityDataServiceUpgradedProvider) {}

  register<T>(key: string, callBack: (id: number | string) => T): void {
    this.provider.registerLoader(key, callBack);
  }

  get<T>(key: string, id: number | string): Observable<T> {
    return from(this.provider.getData(key, id));
  }
}

export function entityDataServiceFactory(injector: angular.auto.IInjectorService): EntityDataService {
  return new EntityDataService(injector.get<EntityDataServiceUpgradedProvider>('entityDataService'));
}

export const entityDataServiceProvider: FactoryProvider = {
  provide: EntityDataService,
  useFactory: entityDataServiceFactory,
  deps: ['$injector'],
};
