import { FactoryProvider } from '@angular/core';
import { NotificationService } from 'ajs/modules/app/environment/notification-service';

export function notificationServiceFactory(injector: angular.auto.IInjectorService): NotificationService {
  return injector.get<NotificationService>('notificationService');
}

export const notificationServiceProvider: FactoryProvider = {
  provide: NotificationService,
  useFactory: notificationServiceFactory,
  deps: ['$injector'],
};
