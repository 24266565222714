import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ISearchFilterUpgraded } from 'app/deprecated/search/models/search-filters.models';
import _ from 'lodash';

@Component({
  standalone: false,
  selector: 'price-form-dep',
  templateUrl: './price-form.component.html',
})
export class PriceFormDepComponent implements OnInit {
  static readonly selector = 'priceForm';
  @Input() filter: ISearchFilterUpgraded;
  @Input() status: any;
  @Input() anyEvent?: boolean;
  @Output() applied = new EventEmitter<boolean>();
  paid = false;

  ngOnInit() {
    this.paid = this.status.paid;
  }

  changeFilter() {
    this.status.changed =
      (this.status.paid &&
        (this.filter.items[0].value !== this.status.minPrice || this.filter.items[1].value !== this.status.maxPrice)) ||
      this.status.paid !== this.paid ||
      this.filter.items[2].value !== this.status.free;

    if (this.anyEvent) {
      this.applyFilter();
    }
  }

  applyFilter() {
    const updatedItems = [];
    let minPrice;
    let maxPrice;

    // save filter values
    if (this.status.paid) {
      minPrice = this.status.minPrice || 0;
      maxPrice = _.isNumber(this.status.maxPrice) ? this.status.maxPrice : '';
    } else {
      minPrice = null;
      maxPrice = null;
    }

    if (this.filter.items[0].value !== minPrice) {
      this.filter.items[0].value = minPrice;
      updatedItems.push(this.filter.items[0]);
    }

    if (this.filter.items[1].value !== maxPrice) {
      this.filter.items[1].value = maxPrice;
      updatedItems.push(this.filter.items[1]);
    }

    if (this.filter.items[2].value !== this.status.free) {
      this.filter.items[2].value = this.status.free ? this.status.free : null;
      updatedItems.push(this.filter.items[2]);
    }

    this.paid = this.status.paid;
    // apply current selection
    const items = _.cloneDeep(updatedItems);

    // Do not need clear because apply only changed items
    _.forEach(items, (item) => {
      this.filter.exec(item.value, item.term);
    });

    // clear variables
    this.status.isOpen = false;
    this.status.changed = false;

    this.applied.emit(true);
  }
}
