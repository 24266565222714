import { FactoryProvider } from '@angular/core';

export abstract class AuthorizationService {
  authorizeState: (stateName: string, params: any) => void;
  isStateAuthorized: (stateName: string, params: any) => void;
  removeCookie: () => void;
  checkRequirements: () => void;
  getAuthorizedStates: () => void;
  getAuthorizationCookie: () => void;
}

export function authorizationServiceFactory(injector: angular.auto.IInjectorService): AuthorizationService {
  return injector.get<AuthorizationService>('authorizationService');
}

export const authorizationServiceProvider: FactoryProvider = {
  provide: AuthorizationService,
  useFactory: authorizationServiceFactory,
  deps: ['$injector'],
};
