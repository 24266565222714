import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ISearchFilterItemUpgraded,
  ISearchFilterItemsPagesQuery,
  ISearchFilterItemsQuery,
  ISearchFilterUpgraded,
} from 'app/deprecated/search/models/search-filters.models';
import { ElmsUtils } from 'core/utils';
import _ from 'lodash';

interface INode extends ISearchFilterItemUpgraded {
  id: number;
  selected: boolean;
  expanded: boolean;
  nodes: INode[];
  parent: INode;
  typeId?: number;
  loading?: boolean;
}

@Component({
  standalone: false,
  selector: 'searchable-multi-choice-content-dep',
  templateUrl: './searchable-multi-choice.component.html',
})
export class SearchableMultiChoiceDepComponent implements OnInit {
  @Input() filter: ISearchFilterUpgraded;

  modalInstance?: NgbActiveModal;
  currentPage = 1;
  itemsPerPage = 10;
  items: INode[] = [];
  itemCount = null;
  query = '';
  searching = false;
  selectedItems = [];
  label: string;
  treeView = false;
  searchQuery: ISearchFilterItemsQuery;

  get isModal(): boolean {
    return !!this.modalInstance;
  }

  ngOnInit(): void {
    this.label = this.filter.label;
    this.selectedItems = this.filter.selectedItems.slice();

    this.initDefaultSearchQuery();
  }

  initDefaultSearchQuery() {
    if (this.treeView) {
      this.searchQuery = {
        treeView: this.treeView,
      };

      if (this.query && this.query !== '') {
        this.searchQuery.group_name = this.query;
      }
    } else {
      this.searchQuery = {
        query: this.query,
        page: this.currentPage,
        rows: this.itemsPerPage,
      };
    }
  }

  isItemAdded(item: ISearchFilterItemUpgraded) {
    return !!_.find(this.selectedItems, { id: item.id });
  }

  applyFilter() {
    this.filter.items = this.selectedItems;
    this.filter.batchExec(_.xor(this.selectedItems, this.filter.selectedItems));

    if (this.isModal) {
      this.modalInstance.close();
    }
  }

  cancel() {
    this.modalInstance?.dismiss();
  }

  search($event: Event) {
    if ($event) {
      $event.preventDefault();
    }

    this.currentPage = 1;
    this.initDefaultSearchQuery();
    this.loadData();

    return false;
  }

  pageChanged(page: number) {
    if (this.itemCount <= this.items.length) {
      return;
    }

    this.currentPage = page;

    (this.searchQuery as ISearchFilterItemsPagesQuery).page = this.currentPage;

    this.loadData();
  }

  loadData() {
    this.searching = true;

    this.filter
      .getItemsPromise(this.searchQuery)
      .then((data) => {
        this.itemCount = data.itemCount;
        this.items = data.items as INode[];
        this.items.forEach((item) => this.initItem(item));
      })
      .finally(() => {
        this.searching = false;
      });
  }

  addItem(item: ISearchFilterItemUpgraded) {
    const itemToAdd = _.find(this.selectedItems, { id: item.id });

    if (!itemToAdd) {
      item.selected = true;
      this.selectedItems.push(item);
    }

    if (!this.modalInstance) {
      this.applyFilter();
    }
  }

  removeItem(item: ISearchFilterItemUpgraded) {
    item.selected = false;
    _.remove(this.selectedItems, { id: item.id });

    if (!this.modalInstance) {
      this.applyFilter();
    }
  }

  getPathToItem(item: ISearchFilterItemUpgraded) {
    if (item.text === item.path) {
      return '';
    }

    return item.path.substring(0, item.path.length - item.text.length);
  }

  changeSearchType() {
    this.itemCount = null;
    this.items = [];
    this.treeView = !this.treeView;
    this.initDefaultSearchQuery();
    this.loadData();
  }

  initItem(item: ISearchFilterItemUpgraded) {
    const itemAdded = _.find(this.selectedItems, { id: item.id });

    if (itemAdded) {
      item.selected = true;
    }
  }

  changeItem(item: ISearchFilterItemUpgraded) {
    if (item.selected) {
      this.addItem(item);
    } else {
      this.removeItem(item);
    }
  }

  expand(node: INode) {
    if (node.nodes && node.nodes.length > 0) {
      node.expanded = !node.expanded;
    } else {
      node.loading = true;
      this.searchQuery = {
        parent: node.id,
        treeView: this.treeView,
      };

      this.filter
        .getItemsPromise(this.searchQuery)
        .then((data) => {
          node.nodes = data.items as INode[];
          node.nodes.forEach((item) => this.initItem(item));
          node.expanded = !node.expanded;
          node.loading = false;
        })
        .finally(() => {
          this.searching = false;
        });
    }
  }

  escapeRegExp(str: string) {
    return ElmsUtils.escapeRegExp(str);
  }
}
