<div *ngFor="let f of filter.subfilters">
  <div [ngSwitch]="f.mode">
    <single-choice-filter-dep
      *ngSwitchCase="'singleChoice'"
      [filter]="f"
      full-screen="fullScreen"
      class="single-choice-filter"></single-choice-filter-dep>
    <popup-multi-choice-filter-dep
      *ngSwitchCase="'popupMultiChoice'"
      [filter]="f"
      full-screen="fullScreen"></popup-multi-choice-filter-dep>
    <multi-choice-filter-dep
      *ngSwitchCase="'multiChoice'"
      [filter]="f"
      [fullScreen]="fullScreen"></multi-choice-filter-dep>
  </div>
</div>
