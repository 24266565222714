import { FactoryProvider } from '@angular/core';
import {
  ISearchFilterOptionsService,
  ISearchFilterOptionsUpgraded,
} from 'app/deprecated/search/models/search-filters.models';

export abstract class CollectionTypesFilterService {
  abstract getTrainingCategoriesFilterConfig(
    config?: Partial<ISearchFilterOptionsUpgraded>,
  ): ISearchFilterOptionsService;
}

export function collectionTypesFilterFactory(injector: angular.auto.IInjectorService): CollectionTypesFilterService {
  return injector.get<CollectionTypesFilterService>('searchFilterCollectionTypes');
}

export const collectionTypesFilterProvider: FactoryProvider = {
  provide: CollectionTypesFilterService,
  useFactory: collectionTypesFilterFactory,
  deps: ['$injector'],
};
