<div class="checkbox">
  <label class="use-hand-cursor no-margin-top no-margin-bottom">
    <input
      type="checkbox"
      [checked]="filter.inverted ? !filter.items[0].selected : filter.items[0].selected"
      (change)="
        filter.items[0].selected = !filter.items[0].selected; filter.exec(filter.items[0].value, filter.term)
      " />
    <span [innerHtml]="filter.label"></span>
  </label>
</div>
