export enum RegistrationErrorsTypes {
  RegistrationExistsException = 'User already registered',
  ActionIsNotAllowedException = 'Action not allowed',
  RegistrationActionNotAllowedException = 'Action not allowed',
  RegistrationDeleteException = "Can't delete the registration",
  RegistrationNotFoundException = "Can't find the registration",
  RegistrationPermissionException = 'No edit permission',
  RegistrationInTargetStateException = 'Already in target status',
  RegistrationInactiveException = 'Registration is inactive',
  NotApprovalBasedException = 'Course is not approval based for user',
  NoPreAssessmentException = 'No pre-assessment',
  NoPostAssessmentException = 'No post-assessment',
  NoEvaluationException = 'No evaluation',
  NoSeatsAvailableException = 'No available seats',
  NoSessionRoleCapacityException = 'Role limit exceeded',
  AnotherActiveRegistrationException = "User can't have multiple active registrations for the same course",
  AnotherSessionRegistrationException = "User can't have multiple registrations for the same session",
  ApproveRegistrationActionNotAllowedException = 'Only Pending or Declined registrations can be approved',
  DeclineRegistrationActionNotAllowedException = 'Only Pending registrations can be declined',
  VerifyRegistrationActionNotAllowedException = 'Only finalized registrations can be verified',
  SessionAvailabilityException = 'Session is not available for user',
  RegistrationOverlappedSessions = 'Session Schedule conflict',
  NoSessionException = 'There is no session in the registration',
  DeactivateVirtualRegistrationException = 'Can not deactivate registration because it actually not exists',
  RegistrationExceededLimitCoursesException = 'User exceeded limit course registrations',
  EmptyWayOfAttendanceRegistrationException = 'There is no Way of Attendance in the registration',
  IncorrectWayOfAttendanceRegistrationException = 'Incorrect Way of Attendance in the registration',
  ComponentRegistrationIsNotAllowedException = 'Component registration is not allowed without parent registration',
  Unknown = 'An error occurred while registration updating',
}

export class RegistrationErrors {
  static get(key: string): string {
    return key ? RegistrationErrorsTypes[key] || RegistrationErrorsTypes.Unknown : null;
  }
}
