import { FactoryProvider } from '@angular/core';
import { ISearchFilterOptionsService } from '../../models/search-filters.models';

export abstract class CertificateFilterService {
  abstract getMyCertificatesFilterConfig(): ISearchFilterOptionsService;
  abstract getCertificateCoursesFilterConfig(): ISearchFilterOptionsService;
}

export function searchCertificateFilterFactory(injector: angular.auto.IInjectorService): ISearchFilterOptionsService {
  return injector.get<ISearchFilterOptionsService>('searchFilterCertificate');
}

export const searchCertificateFilterProvider: FactoryProvider = {
  provide: CertificateFilterService,
  useFactory: searchCertificateFilterFactory,
  deps: ['$injector'],
};
