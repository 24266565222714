import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { CourseRegistrationStatusComponent } from 'modules/course-registrations/components/course-registration-status.component';
import { CatalogSearchFiltersComponent } from 'modules/course/catalog/components/catalog-search-filters.component';
import { RecentlyUpdatedItemsComponent } from 'modules/course/catalog/components/recently-updated-items.component';
import { SubjectAreaNavigationItemsComponent } from 'modules/course/catalog/components/subject-area-navigation-items.component';
import { TargetAudienceNavigationItemsComponent } from 'modules/course/catalog/components/target-audience-navigation-items.component';
import { CourseExpirationDateComponent } from 'modules/course/common/components/course-expiration-date.component';
import { CourseLengthComponent } from 'modules/course/common/components/course-length.component';
import { CourseStarRatingComponent } from 'modules/course/common/components/course-star-rating.component';
import { CourseThumbnailComponent } from 'modules/course/common/components/course-thumbnail.component';
import { StarRatingComponent } from 'modules/course/common/components/star-rating.component';
import { UserFeaturedCoursesComponent } from 'modules/course/custom-lists/componenets/user-featured-courses.component';
import { CollectionPlayerComponent } from 'modules/course/player/collection/components/collection-player.component';
import { CourseQuizPlayerComponent } from 'modules/course/player/course-quiz/course-quiz-player.component';
import { CourseReviewService } from 'modules/course/reviews/services/course-review.service';
import { HorizontalCourseTileComponent } from 'modules/course/views/tiles/components/horizontal-course-tile.component';
import { SimpleCourseTileComponent } from 'modules/course/views/tiles/components/simple-course-tile.component';
import { CoursePriceComponent } from 'modules/course/common/components/course-price.component';

declare const angular: angular.IAngularStatic;

export default angular
  .module('kmi.lms.core')
  .directive(
    SimpleCourseTileComponent.selector,
    downgradeComponent({ component: SimpleCourseTileComponent }) as angular.IDirectiveFactory,
  )
  .directive(
    CoursePriceComponent.selector,
    downgradeComponent({ component: CoursePriceComponent }) as angular.IDirectiveFactory,
  )
  .directive(
    CourseLengthComponent.selector,
    downgradeComponent({ component: CourseLengthComponent }) as angular.IDirectiveFactory,
  )
  .directive(
    CourseExpirationDateComponent.selector,
    downgradeComponent({ component: CourseExpirationDateComponent }) as angular.IDirectiveFactory,
  )
  .directive(
    StarRatingComponent.selector,
    downgradeComponent({ component: StarRatingComponent }) as angular.IDirectiveFactory,
  )
  .directive(
    CourseRegistrationStatusComponent.selector,
    downgradeComponent({ component: CourseRegistrationStatusComponent }) as angular.IDirectiveFactory,
  )
  .directive(
    RecentlyUpdatedItemsComponent.selector,
    downgradeComponent({ component: RecentlyUpdatedItemsComponent }) as angular.IDirectiveFactory,
  )
  .directive(
    CatalogSearchFiltersComponent.selector,
    downgradeComponent({ component: CatalogSearchFiltersComponent }) as angular.IDirectiveFactory,
  )
  .directive(
    CourseStarRatingComponent.selector,
    downgradeComponent({ component: CourseStarRatingComponent }) as angular.IDirectiveFactory,
  )
  .directive(
    UserFeaturedCoursesComponent.selector,
    downgradeComponent({ component: UserFeaturedCoursesComponent }) as angular.IDirectiveFactory,
  )
  .directive(
    SubjectAreaNavigationItemsComponent.selector,
    downgradeComponent({ component: SubjectAreaNavigationItemsComponent }) as angular.IDirectiveFactory,
  )
  .directive(
    TargetAudienceNavigationItemsComponent.selector,
    downgradeComponent({ component: TargetAudienceNavigationItemsComponent }) as angular.IDirectiveFactory,
  )
  .directive(
    CourseQuizPlayerComponent.selector,
    downgradeComponent({ component: CourseQuizPlayerComponent }) as angular.IDirectiveFactory,
  )
  .directive(
    CourseThumbnailComponent.selector,
    downgradeComponent({ component: CourseThumbnailComponent }) as angular.IDirectiveFactory,
  )
  .directive(
    CollectionPlayerComponent.selector,
    downgradeComponent({ component: CollectionPlayerComponent }) as angular.IDirectiveFactory,
  )
  .directive(
    HorizontalCourseTileComponent.selector,
    downgradeComponent({ component: HorizontalCourseTileComponent }) as angular.IDirectiveFactory,
  )
  .factory('CourseReviewServiceUpgraded', downgradeInjectable(CourseReviewService));
