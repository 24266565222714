import { FactoryProvider } from '@angular/core';
import { ISearchFilterOptionsService, ISearchFilterOptionsUpgraded } from '../../models/search-filters.models';

export abstract class ObjectFilterService implements ISearchFilterOptionsService {
  abstract getOptions(options?: Partial<ISearchFilterOptionsUpgraded>): ISearchFilterOptionsUpgraded;
}

export function searchObjectFilterFactory(injector: angular.auto.IInjectorService): ISearchFilterOptionsService {
  return injector.get<ISearchFilterOptionsService>('searchFilterObject');
}

export const searchObjectFilterProvider: FactoryProvider = {
  provide: ObjectFilterService,
  useFactory: searchObjectFilterFactory,
  deps: ['$injector'],
};
