import { Injectable } from '@angular/core';
import { StateService, UIRouterGlobals, UrlService } from '@uirouter/core';
import { HttpRequestsCounterInterceptor } from 'core/http-interceptors/http-requests-counter-interceptor';
import { BackUrlService } from 'core/navigation/back-url.service.ajs-upgraded-provider';
import { LocationHistoryService } from 'core/navigation/location-history.service.ajs-upgraded-provider';
import { asyncScheduler, observeOn, takeWhile } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class ScrollService {
  constructor(
    private requestInterceptor: HttpRequestsCounterInterceptor,
    private document: Document,
    private urlService: UrlService,
    private stateService: StateService,
    private activeState: UIRouterGlobals,
    private window: Window,
    private locationHistory: LocationHistoryService,
    private backUrlService: BackUrlService,
  ) {}

  getActiveRequestsStatus() {
    return this.requestInterceptor.activeRequestsStatus.asObservable();
  }

  waitForScroll() {
    return this.requestInterceptor.activeRequestsStatus.pipe(
      observeOn(asyncScheduler),
      tap((isActive) => {
        if (!isActive) {
          this.scrollToSection();
        }
      }),
      takeWhile((isActive) => isActive),
    );
  }

  scrollToSection(activeSection?: string, toSection?: string) {
    setTimeout(() => {
      toSection = toSection || this.urlService.search().section;

      if (Number.isInteger(toSection) && activeSection) {
        return false;
      } else if (activeSection === toSection) {
        return true;
      } else {
        this.scrollWithOffset(document, toSection, 200, true);

        if (toSection !== this.urlService.search().section) {
          this.highlightSection(toSection);
        }
      }
    });
  }

  getOffsetTop(element) {
    return element ? element.offsetTop + this.getOffsetTop(element.offsetParent) : 0;
  }

  scrollWithOffset(document, idOrName, scrollOffset, offsetAll) {
    //find element with the give id of name and scroll to the first element it finds
    if (!idOrName) {
      this.window.scrollTo(0, 0);
    }

    //check if an element can be found with id attribute
    let el = document.getElementById(idOrName);

    if (!el) {
      //check if an element can be found with name attribute if there is no such id
      el = document.getElementsByName(idOrName);

      el = el && el.length ? el[0] : null;
    }

    if (el) {
      //if an element is found, scroll to the element
      el.scrollIntoView({
        behavior: 'smooth',
        inline: 'nearest',
        block: 'center',
      });

      if (typeof scrollOffset === 'number') {
        //then move down window by size of offset of elem
        const offsetTop = offsetAll ? this.getOffsetTop(el) : el.getBoundingClientRect().top;

        this.window.scrollTo({
          left: 0,
          top: offsetTop - scrollOffset,
          behavior: 'smooth',
        });
      }
    }
    //otherwise, ignore
  }

  highlightSection(section: string) {
    const params = Object.assign({}, this.activeState.params, { section });

    if (this.activeState.transition?.isActive()) {
      this.locationHistory.push({
        name: this.activeState.current.name,
        params,
      });

      this.backUrlService.bypassHistory();
    } else {
      this.stateService.go(this.activeState.current, params);
    }
  }

  highlightSectionAndGo(sectionName: string, func: CallableFunction) {
    setTimeout(() => {
      this.highlightSection(sectionName);

      if (func) {
        setTimeout(func);
      }
    });
  }

  scrollInto(idOrName, block?) {
    idOrName = idOrName || 'lms-main-container';
    let el = this.document.querySelector('[id^="' + idOrName + '"]');

    if (!el) {
      const els = document.getElementsByName(idOrName);

      el = els && els.length ? els[0] : null;
    }

    if (el) {
      el.scrollIntoView({ behavior: 'smooth', inline: 'nearest', block: block || 'start' });
    }
  }
}
