import { downgradeInjectable } from '@angular/upgrade/static';
import { ResourceService } from 'modules/resources/services/resource.service';
import { lastValueFrom, map } from 'rxjs';

declare const angular: angular.IAngularStatic;

function moduleRun(entityDataService, $injector) {
  entityDataService.registerLoader('resource', function (id: string) {
    const resourceService = $injector.get('Resource') as ResourceService;

    return lastValueFrom(
      resourceService.get(Number(id)).pipe(map((resource) => Object.assign({ $resolved: true }, resource))),
    );
  });
}

export default angular.module('elmsSpaApp').factory('Resource', downgradeInjectable(ResourceService)).run(moduleRun);
