import { CompetencyFilterDepComponent } from './modal/competency-filter.component';
import { CourseAttributeFilterDepComponent } from './modal/course-attribute-filter.component';
import { GroupTagsFilterDepComponent } from './modal/group-tags-filter.component';
import { SessionLabelFilterDepComponent } from './modal/session-label-filter.component';

export const filterMappingDeprecated = {
  competencyFilter: CompetencyFilterDepComponent,
  sessionLabelFilter: SessionLabelFilterDepComponent,
  groupTagsSearchFilter: GroupTagsFilterDepComponent,
  courseAttributeFilter: CourseAttributeFilterDepComponent,
};
