import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ElmsUtils } from 'core/utils';
import { Observable } from 'rxjs';
import { IVolunteerAgreement } from '../models/volunteer-agreement.model';

export enum VolunteerAgreementEndpoints {
  GET = '/api-volunteers/agreements/:agreementId/',
  QUERY = '/api-volunteers/agreements/',
  PUT = '/api-volunteers/agreements/:agreementId/',
  POST = '/api-volunteers/agreements/',
  DELETE = '/api-volunteers/agreements/:agreementId/',
}

@Injectable()
export class VolunteerAgreementService {
  constructor(protected http: HttpClient) {}

  get(agreementId: number): Observable<IVolunteerAgreement> {
    return this.http.get<IVolunteerAgreement>(
      ElmsUtils.formatUrl(VolunteerAgreementEndpoints.GET, { agreementId: agreementId }),
    );
  }

  query(params: { active?: boolean }): Observable<IVolunteerAgreement[]> {
    return this.http.get<IVolunteerAgreement[]>(VolunteerAgreementEndpoints.QUERY, {
      params: new HttpParams({ fromObject: params }),
    });
  }
}
