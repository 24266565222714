import { GoogleAnalytics } from './analytics';
import { FontawesomeLoader } from './fontawesome';
import { OlarkClient } from './olark';
import { OneTrustCookieManager } from './oneTrust';

export function configureThirdParties(environmentSettings) {
  const googleAnalytics = new GoogleAnalytics(environmentSettings);
  googleAnalytics.setup();

  const olarkClient = new OlarkClient(environmentSettings.globalConfig.settings.olarkCode);
  olarkClient.setup();

  const cookieManager = new OneTrustCookieManager(environmentSettings.globalConfig.oneTrustCookieId);
  cookieManager.setup();

  const fontawesomeLoader = new FontawesomeLoader();
  fontawesomeLoader.setup();
}
