import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UrlService } from '@uirouter/core';
import { IUrlParams, UrlParamHandler } from 'core/navigation/models/navigation-url.model';
import _ from 'lodash';
import { ISearchFilterItemUpgraded, ISearchFilterUpgraded } from '../../models/search-filters.models';
import { filterMappingDeprecated } from './index';

@Component({
  standalone: false,
  selector: 'single-choice-filter-dep',
  templateUrl: './single-choice-filter.component.html',
})
export class SingleChoiceFilterDepComponent implements OnInit, OnDestroy {
  @Input() filter: ISearchFilterUpgraded;
  @Input() isOpen?: boolean;
  @Input() fullScreen?: boolean;
  selectedItems: ISearchFilterItemUpgraded[] = [];
  changed = false;
  searchQuery: string | null = null;
  status = {
    isOpen: this.isOpen,
    changed: false,
  };

  displayLimit: number;
  private urlHandlerDestroy: UrlParamHandler;
  private urlParams: IUrlParams;
  private modalInstance?: NgbModalRef;

  constructor(
    private urlService: UrlService,
    private ngModal: NgbModal,
    private injector: Injector,
  ) {}

  get filteredFilterItems() {
    return _.filter(this.filter.items, (item) => {
      return this.searchQuery ? item.text.toLowerCase().includes(this.searchQuery.toLowerCase()) : true;
    }).slice(0, this.fullScreen ? undefined : this.displayLimit);
  }

  ngOnInit(): void {
    this.displayLimit = this.filter.displayLimit;

    this.ngOnUrlChange();
    this.urlHandlerDestroy = <UrlParamHandler>this.urlService.onChange(() => this.ngOnUrlChange());
  }

  ngOnDestroy(): void {
    this.urlHandlerDestroy();
  }

  ngOnUrlChange() {
    const params = _.pickBy(this.urlService.search(), _.identity);

    if (!_.isEqual(params, this.urlParams)) {
      this.urlParams = params;
      this.filter.extractConditions();

      if (!this.filter.visible) {
        this.searchQuery = null;
      }

      this.selectedItems = [...this.filter.selectedItems];

      if (this.modalInstance) {
        this.modalInstance.dismiss();
      }
    }
  }

  moreItems() {
    this.displayLimit = this.filter.items.length;

    return false;
  }

  lessItems() {
    this.displayLimit = this.filter.displayLimit;

    return false;
  }

  isAllSelected() {
    return _.every(this.filter.items, 'selected');
  }

  selectAll(items: ISearchFilterItemUpgraded[]) {
    const value = !this.isAllSelected();

    items.forEach((item) => {
      if (item.selected !== value) {
        this.filter.exec(item.value, item.term);
      }

      item.selected = value;
    });
  }

  searchQueryChanged() {
    if (this.searchQuery?.length) {
      this.moreItems();
    } else {
      this.lessItems();
    }
  }

  openFilter() {
    this.modalInstance = this.ngModal.open(filterMappingDeprecated[this.filter.popupOptions.component], {
      injector: this.injector,
    });
    this.modalInstance.componentInstance.filter = this.filter;
    this.modalInstance.componentInstance.modalInstance = this.modalInstance;
  }

  addItem($event, item: ISearchFilterItemUpgraded) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }

    this.selectedItems = [item];
    this.changed = !_.isEqual(this.selectedItems, this.filter.selectedItems);
  }

  clearSelection() {
    this.selectedItems = [];
    this.changed = !_.isEqual(this.selectedItems, this.filter.selectedItems);
  }

  applySelection() {
    if (this.selectedItems.length) {
      this.filter.exec(this.selectedItems[0].value);
    } else {
      this.filter.clearSelection();
    }

    this.changed = false;
    this.isOpen = false;
  }
}
