import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { StateDeclaration } from '@uirouter/core/lib/state/interface';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { GlobalConfig } from 'core/environment';
import { ITargetState } from 'core/models/navigation.models';
import { NavigationJsService } from 'core/navigation/services/navigationjs.service.ajs-upgraded-provider';
import { LmsCookiesService } from 'core/services/cookies/lms-cookies.service';
import { SecurityService } from 'core/services/security.service.ajs-upgraded-provider';
import { ISiteSettings } from 'core/site-settings/models/site-settings.model';
import { SiteSettingsService } from 'core/site-settings/services/site-settings.service';
import { MenuService } from 'modules/navigation/services/menu.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class NavigationService {
  navigationTargetCookieName: string;
  targetState: ITargetState;
  currentSiteSetting: ISiteSettings;

  constructor(
    private stateService: StateService,
    private globalConfig: GlobalConfig,
    private cookieService: CookieService,
    private siteSettingsService: SiteSettingsService,
    private securityService: SecurityService,
    private menuService: MenuService,
    private currentUserService: CurrentUserService,
    private navigationJsService: NavigationJsService,
  ) {
    this.navigationTargetCookieName = LmsCookiesService.cookiePrefix + '.navigationTarget';
    this.initialize();
    this.extractTarget();
  }

  getState(name: string): StateDeclaration {
    const state = this.stateService.get(name);
    const environments = state.data.environment as string[];

    return state && (!state.data.environment || environments?.includes(this.globalConfig.environment)) ? state : null;
  }

  setTargetState(state: ITargetState): void {
    let targetState;

    if (
      !targetState &&
      !this.globalConfig.settings.deepLinkingExcludedStates.includes(state.name) &&
      (!this.menuService.getFirstMenuState() || this.menuService.getFirstMenuState().stateName !== state.name)
    ) {
      this.targetState = {
        name: state.name,
        params: state.params,
      };

      const date = new Date();

      date.setTime(date.getTime() + 20 * 1000);
      // store cookie for 20 seconds to prevent target loss after page refresh
      this.cookieService.set(this.navigationTargetCookieName, JSON.stringify(this.targetState), date);
    }
  }

  getTargetState(checkAvailability = true): { name: string; params: any } | null {
    return this.targetState && (!checkAvailability || this.securityService.isStateAvailable(this.targetState.name))
      ? this.targetState
      : null;
  }

  clearTargetState(): void {
    this.targetState = null;
  }

  getRelativeTargetPath(): string | null {
    const currentUser = this.currentUserService.get();

    // We have two application and possible that target set from js application
    // For these reason now we need to have ability extract target state before get
    this.extractTarget();
    const path = this.targetState ? this.stateService.href(this.targetState.name, this.targetState.params) : null;

    if (path) {
      return path;
    } else if (
      currentUser.roles &&
      currentUser.roles.length &&
      this.globalConfig.settings?.login?.forwardAdminToAdminApp
    ) {
      const basePath = this.currentSiteSetting.basePath || 'lms';

      return `/${basePath}/admin`;
    }
  }

  /** Initialize asynchronously here */
  private async initialize(): Promise<void> {
    this.currentSiteSetting = await this.siteSettingsService.getCurrent();
  }

  private extractTarget(): void {
    const targetState = this.cookieService.get(this.navigationTargetCookieName);

    if (targetState) {
      this.targetState = JSON.parse(targetState);
    }

    this.navigationJsService.extractTarget();
  }
}
